<app-header></app-header>


<div *ngIf="isproject; else pageTemplate">
	<div *ngIf="image_slide">
		<div class="project-slide-desktop d-none d-md-block" [style.background-image]="'url(' + image_slide + ')'"></div>
	</div>
	<section class="section-widget">
		<div class="container py-3">
			<div class="project-card">
				<div class="row">
					<div class="col-12 col-md-8">
						<div class="d-none d-md-block">
							<div *ngIf="genre">
								<span class="tag-item active left">{{genre}}</span>
							</div>
							<div class="clear_15"></div>
							<h1 [innerHTML]="title"></h1>
							<div class="gray-h3" *ngIf="on_air_date_plain">{{on_air_date_plain}} {{on_air_time_plain}}</div>
							<div class="clear_15"></div>
						</div>
						<div class="d-md-none">
							<div class="project-slide-mobile" *ngIf="image_slide" [style.background-image]="'url(' + image_slide + ')'">
								<div class="project-slide-mobile-content">
									<div *ngIf="genre">
										<span class="tag-item left active big">{{genre}}</span>
									</div>
									<h2 [innerHTML]="title"></h2>
									<div class="gray-h3" *ngIf="on_air_date_plain">{{on_air_date_plain}} {{on_air_time_plain}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div *ngIf="country">
							<h4 class="gray-h4" translate>Country</h4>
							<div class="after-live-item">
								{{ country }}
							</div>	
							<div class="clear_5 d-none d-md-block"></div>
						</div>
					</div>				
				</div>
			</div>
			<div class="clear_15"></div>
			<div class="project-menu">
				<ul class="nav justify-content-center">
					<li class="nav-item" *ngIf="playlists">
						<a class="nav-link scroll-link" href="#project-playlists" translate>Playlists</a>
					</li>		
					<li class="nav-item" *ngIf="content">
						<a class="nav-link scroll-link" href="#project-about" translate>About Project</a>
					</li>
					<li class="nav-item" *ngIf="photos">
						<a class="nav-link scroll-link" href="#project-gallery" translate>Photo gallery</a>
					</li>
					<li class="nav-item" *ngIf="hosts">
						<a class="nav-link scroll-link" href="#project-host" translate>Leaders</a>
					</li>
					<li class="nav-item" *ngIf="anketa > 0">
						<a class="nav-link scroll-link" href="#project-anketa" translate>Profile</a>
					</li>
					<li class="nav-item" *ngIf="news">
						<a class="nav-link scroll-link" href="#project-news" translate>News</a>
					</li>			
				</ul>		
			</div>
			<div class="clear_60"></div>
			<div *ngIf="playlists">
				<div id="project-playlists">
					<div *ngFor="let playlist of playlists">
						<div *ngIf="playlist">
							<div class="horizontal-widget">
								<h2 class="horizontal-widget-title">
									{{playlist.title}} <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
								</h2>
								<owl-carousel [options]="CarouselOptions" [items]="playlist.items" [carouselClasses]="['standart_carousel_four']" >  
									<div class="item" *ngFor="let pl_item of playlist.items">  
										<a href="{{pl_item.url}}" class="animated-image">
											<img src="{{pl_item.img}}" class="w-100">
										</a>
										<a href="{{pl_item.url}}" class="section-widget-title">{{pl_item.title}}</a>
										<p class="section-widget-meta">{{pl_item.date}}</p>
										<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}">{{pl_item.project_name}}</a></p>
										<div class="clear_15 d-lg-none"></div> 
									</div>  
								</owl-carousel>							
								<div class="text-center d-md-none">
									<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
								</div>														
							</div>
							<div class="clear_40"></div>
						</div>
					</div>	
					<div *ngIf="more_btn_enable">
						<div class="text-center" *ngIf="all_videos_url">
							<a href="{{all_videos_url}}" class="section-widget-btn m-0" translate>More Videos</a>
						</div>	
					</div>
				</div>
				<div class="clear_60"></div>
			</div>
			
			<div *ngIf="horizontal_ads_1_img; else adsHorizontal1Template">
				<div class="text-center">
					<a href="{{horizontal_ads_1_url}}" target="_blank" rel="nofollow">
						<img src="{{horizontal_ads_1_img}}" class="w-100">
					</a>		
				</div>
				<div class="clear_60"></div>
			</div>
			<ng-template #adsHorizontal1Template>
				<div class="text-center">
					<div id="div-gpt-ad-1516984738506-5"></div>
				</div>
				<div class="clear_60"></div>
			</ng-template>			
			
			<div *ngIf="content">		
				<div id="project-about">
					<h2 class="text-left text-md-center" translate>About Project</h2>
					<div class="project-about-description" [innerHTML]="content"></div>
					<!--
					<div class="text-center">
						<a href="" class="project-about-description-more" translate>Read completely</a>
					</div>
					-->
				</div>			
				<div class="clear_60"></div>		
			</div>
			
			<div *ngIf="horizontal_ads_2_img; else adsHorizontal2Template">
				<div class="text-center">
					<a href="{{horizontal_ads_2_url}}" target="_blank" rel="nofollow">
						<img src="{{horizontal_ads_2_img}}" class="w-100">
					</a>		
				</div>
				<div class="clear_60"></div>
			</div>
			<ng-template #adsHorizontal2Template>
				<div class="text-center">
					<div id="div-gpt-ad-1516984738506-1"></div>
				</div>
				<div class="clear_60"></div>
			</ng-template>				
			
			<div *ngIf="photos">		
				<div id="project-gallery">
					<h2 class="text-left text-md-center" translate>Photo gallery</h2>
					<div class="clear_15 d-none d-md-block"></div>
					<div class="row px-2 justify-content-center">
						<div class="col-12 col-md-4 px-2" *ngFor="let photo of photos">
							<div class="project_gallery_item" *ngIf="photo" [style.background-image]="'url(' + photo + ')'"></div>
							<div class="clear_15"></div>
						</div>			
					</div>		
				</div>
				<div class="clear_60"></div>	
			</div>
			<div *ngIf="hosts">
				<div id="project-host">
					<h2 class="text-left text-md-center" translate>Leaders</h2>
					<div class="clear_15 d-none d-md-block"></div>
					<div class="row px-2 justify-content-center">
						<div class="col-12 col-md-6 px-2" *ngFor="let host of hosts">
							<img src="{{host.img}}" class="w-100">
							<div class="project-host-title">{{host.name}}</div>
							<p [innerHTML]="host.description"></p>
							<ng-container *ngIf="host.message">
								<div class="dropdown">
									<a class="dropdown-toggle" data-toggle="dropdown" href="#moreModal{{host.id}}" translate>More</a>
									<div class="dropdown-menu w-100">
										<div class="container w-100">
											<div class="clear_15"></div>
											<p [innerHTML]="host.message"></p>
										</div>
									</div>
								</div>
							</ng-container>
							<div class="clear_15"></div>						
						</div>		
					</div>		
				</div>
				<div class="clear_40 d-none d-md-block"></div>
			</div>
			
			<div *ngIf="horizontal_ads_3_img; else adsHorizontal3Template">
				<div class="text-center">
					<a href="{{horizontal_ads_3_url}}" target="_blank" rel="nofollow">
						<img src="{{horizontal_ads_3_img}}" class="w-100">
					</a>		
				</div>
				<div class="clear_60"></div>
			</div>
			<ng-template #adsHorizontal3Template>
				<div class="text-center">
					<div id="div-gpt-ad-1516984738506-2"></div>
				</div>
				<div class="clear_60"></div>
			</ng-template>				
			
			<div *ngIf="anketa == 1">
				<div id="project-anketa">
					<h2 class="text-center" translate>Profile</h2>
					<div class="clear_15 d-none d-md-block"></div>
					<p class="text-center" translate>To participate in the show, please fill out and submit the information</p>
					<div class="clear_40"></div>
					<form class="form-anketa" #profileForm="ngForm" (ngSubmit)="onFormSubmit(profileForm)">
						<div class="row">
							<div class="col-12 col-md-5">
								<div class="form-group">
									<label translate>Name</label>
									<input type="text" name="fio" class="form-control" ngModel required #profileFio="ngModel">
									<ng-container *ngIf="fio_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>									
									<div class="clear_5"></div>
								</div>
								<div class="form-group">
									<label translate>Phone</label>
									<input type="text" name="phone" class="form-control" ngModel #profilePhone="ngModel">
									<ng-container *ngIf="phone_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>
								<div class="form-group">
									<label>E-mail</label>
									<input type="email" name="email" class="form-control" ngModel #profileEmail="ngModel">
									<ng-container *ngIf="email_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>						
							</div>
							<div class="col-12 col-md-7">
								<div class="form-group">
									<label translate>Describe your story</label>
									<textarea name="msg" class="form-control" style="height: 220px;" ngModel required #profileMsg="ngModel"></textarea>
									<ng-container *ngIf="msg_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>
							</div>		
						</div>	
						<div class="clear_5"></div>
						<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>Your profile has been sent successfully!</div>
						<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
						<div class="text-right">
							<button type="submit" class="tag-item big active" translate>Send Application</button>
						</div>
					</form>
				</div>	
				<div class="clear_60"></div>
			</div>
			
			<div *ngIf="anketa == 2">
				<div id="project-anketa">
					<h2 class="text-center" translate>Profile</h2>
					<div class="clear_15 d-none d-md-block"></div>
					<p class="text-center" translate>To participate in the show, please fill out and submit the information</p>
					<div class="clear_40"></div>
					<form class="form-anketa" #profileForm="ngForm" (ngSubmit)="onFormSubmit(profileForm)">
						<div class="row">
							<div class="col-12 col-md-5">
								<div class="form-group">
									<label translate>Name</label>
									<input type="text" name="fio" class="form-control" ngModel required #profileFio="ngModel">
									<ng-container *ngIf="fio_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>									
									<div class="clear_5"></div>
								</div>
								<div class="form-group">
									<label translate>Date of Birth</label>
									<input type="text" name="date_birth" class="form-control" ngModel #profileDateBirth="ngModel">
									<ng-container *ngIf="datebirth_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>									
								<div class="form-group">
									<label translate>Phone</label>
									<input type="text" name="phone" class="form-control" ngModel #profilePhone="ngModel">
									<ng-container *ngIf="phone_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>		
								<div class="form-group">
									<label translate>Place of residence</label>
									<input type="text" name="liveplace" class="form-control" ngModel #profileLivePlace="ngModel">
									<ng-container *ngIf="liveplace_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>									
							</div>
							<div class="col-12 col-md-7">
								<div class="form-group">
									<label translate>Describe the situation</label>
									<textarea name="msg" class="form-control" style="height: 220px;" ngModel required #profileMsg="ngModel"></textarea>
									<ng-container *ngIf="msg_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>										
									<div class="clear_5"></div>
								</div>
							</div>		
						</div>	
						<div class="clear_5"></div>
						<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>Your profile has been sent successfully!</div>
						<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
						<div class="text-right">
							<button type="submit" class="tag-item big active" translate>Send Application</button>
						</div>
					</form>
				</div>	
				<div class="clear_60"></div>
			</div>			
			
			<div *ngIf="news">		
				<div id="project-news">
					<h2 class="text-left text-md-center" translate>News</h2>
					<div class="clear_15 d-none d-md-block"></div>
						<div class="row">
							<div class="col-12 col-md-3" *ngFor="let new of news">
								<a href="{{new.url}}" class="animated-image">
									<img src="{{new.img}}" class="w-100">
								</a>
								<div class="clear_15"></div>
								<div class="article-item-meta-box">
									<a href="{{new.category_url}}" class="tag-item left active">{{new.category}}</a>
									<p class="section-widget-meta">{{new.date}}</p>						
								</div>	
								<a href="{{new.url}}" class="section-widget-title">{{new.title}}</a>
								<div class="clear_15"></div>
							</div>				
						</div>
					<div class="clear_15 d-none d-md-block"></div>
					<div class="text-center">
						<a href="{{all_news_url}}" class="section-widget-btn m-0" translate>More News</a>
					</div>				
				</div>				
				<div class="clear_60"></div>	
			</div>
			<div *ngIf="projects">
				<h2 translate>TV Projects</h2>
				<div class="clear_15 d-none d-md-block"></div>
				<div class="row p-0 m-0">
					<div class="col-12 col-md-4 p-0 m-0" *ngFor="let project of projects">
						<a href="{{project.url}}" class="animated-image">
							<img src="{{project.img}}" class="w-100">
						</a>
					</div>
				</div>	
				<div class="clear_40 d-none d-md-block"></div>
				<div class="clear_24 d-md-none"></div>
				<div class="text-center">
					<a href="{{ urlLanguage }}/programs" class="section-widget-btn m-0" translate>All TV projects</a>
				</div>				
			</div>
		</div>
	</section>
</div>

<ng-template #pageTemplate>
	<section>
		<div class="container py-5">	
			<h1 class="article-title" [innerHTML]="title"></h1>
			<div class="clear_15"></div>
			<div class="row">
				<div class="col-12 col-md-8">
					<ng-container *ngIf="vcategories;">
						<div class="videoteka-tags compact" *ngIf="vcategories;">
							<a class="tag-item active" href="{{ urlLanguage }}/video" translate>All</a> 
							<a *ngFor="let vcategory of vcategories" class="tag-item" href="{{vcategory.url}}">{{vcategory.title}}</a> 
							<div class="clear_40"></div>
						</div>
					</ng-container>
					<ng-container *ngIf="is_about">
						<!--
						<div class="videoteka-tags">
						
							<a *ngIf="slug.includes('about') === true; else noselect1Template" class="tag-item active" href="{{ urlLanguage }}/about" translate>About</a> 
							<ng-template #noselect1Template>
								<a class="tag-item" href="{{ urlLanguage }}/about" translate>About</a> 
							</ng-template>
							
							<a *ngIf="slug.includes('awards') === true; else noselect2Template" class="tag-item active" href="{{ urlLanguage }}/awards" translate>Awards</a> 
							<ng-template #noselect2Template>
								<a class="tag-item" target="_blank" href="{{ urlLanguage }}/news/category/awards" translate>Awards</a> 
							</ng-template>
							
							<a *ngIf="slug.includes('vacancy') === true; else noselect3Template" class="tag-item active" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a> 
							<ng-template #noselect3Template>
								<a class="tag-item" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a> 
							</ng-template>		

							<a *ngIf="slug.includes('about/coordinates') === true; else noselect4Template" class="tag-item active" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a> 
							<ng-template #noselect4Template>
								<a class="tag-item" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a> 
							</ng-template>		

							<a *ngIf="slug.includes('sputnik') === true; else noselect5Template" class="tag-item active" href="{{ urlLanguage }}/sputnik" translate>Satellite</a> 
							<ng-template #noselect5Template>
								<a class="tag-item" href="{{ urlLanguage }}/sputnik" translate>Satellite</a> 
							</ng-template>		

							<a *ngIf="slug.includes('castings') === true; else noselect6Template" class="tag-item active" href="{{ urlLanguage }}/castings" translate>Castings</a> 
							<ng-template #noselect6Template>
								<a class="tag-item" href="{{ urlLanguage }}/castings" translate>Castings</a> 
							</ng-template>								
							 
							<a class="tag-item" href="http://інтер.укр" target="_blank" rel="nofollow" translate>Ownership structure</a>

							<a class="tag-item" href="{{ urlLanguage }}/news/inter-media-group-odnovremenno-perevela-veshanie-svoih-telekanalov-v-format-hd" translate>Broadcasting in HD</a>
							
						</div>
						-->

						<div class="clear_40"></div>
					</ng-container>
					<ng-container *ngIf="is_scool">
						<div class="videoteka-tags">
						
							<a *ngIf="slug == 'interschool'; else ns1Template" class="tag-item active" href="{{ urlLanguage }}/interschool" translate>About Interschool</a> 
							<ng-template #ns1Template>
								<a class="tag-item" href="{{ urlLanguage }}/interschool" translate>About Interschool</a> 
							</ng-template>		

							<a *ngIf="slug.includes('interschool-specialty') === true; else ns2Template" class="tag-item active" href="{{ urlLanguage }}/interschool-specialty" translate>Media Specializations</a> 
							<ng-template #ns2Template>
								<a class="tag-item" href="{{ urlLanguage }}/interschool-specialty" translate>Media Specializations</a> 
							</ng-template>									

							<a *ngIf="slug.includes('interschool-partners') === true; else ns3Template" class="tag-item active" href="{{ urlLanguage }}/interschool-partners" translate>Partners</a> 
							<ng-template #ns3Template>
								<a class="tag-item" href="{{ urlLanguage }}/interschool-partners" translate>Partners</a> 
							</ng-template>									

							<a class="tag-item" href="{{ urlLanguage }}/news/category/news" translate>News</a> 
							
							<a *ngIf="slug.includes('interschool-coordinates') === true; else ns4Template" class="tag-item active" href="{{ urlLanguage }}/interschool-coordinates" translate>Contacts</a> 
							<ng-template #ns4Template>
								<a class="tag-item" href="{{ urlLanguage }}/interschool-coordinates" translate>Contacts</a> 
							</ng-template>								

							<a *ngIf="slug.includes('interschool-casting') === true; else ns6Template" class="tag-item active" href="{{ urlLanguage }}/interschool-casting" translate>Apply</a> 
							<ng-template #ns6Template>
								<a class="tag-item" href="{{ urlLanguage }}/interschool-casting" translate>Apply</a> 
							</ng-template>								

						</div>		
						<div class="clear_40"></div>
					</ng-container>					
					<div *ngIf="first_video">
						<div class="videos-page-top">
							<div class="row" *ngFor="let current_first_video of first_video">
								<div class="col-12 col-md-6">
									<div class="video-box-iframe">
										<iframe *ngIf="url" width="100%" height="600" [src]="url" frameborder="0" scrolling="no" allowfullscreen></iframe>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<h2 [innerHTML]="current_first_video.title"></h2>
									<p class="section-widget-meta">{{current_first_video.date}}</p>
									<div class="clear_15"></div>
									<p [innerHTML]="current_first_video.announce"></p>		
									<div class="horizontal-widget-more">
										<a href="{{current_first_video.url}}"><span translate>More</span> <img src="assets/img/gray_arrow_right.svg"></a>
									</div>							
								</div>
							</div>
						</div>
						<div class="clear_40"></div>
					</div>
					<div *ngIf="content">		
						<article>
							<div [innerHTML]="content"></div>		
						</article>	
					</div>	
					<div *ngIf="vacancies">
						<div class="vacancy-item mb-5" *ngFor="let vacancy of vacancies">
							<h2>{{vacancy.title}}</h2>
							<div [innerHTML]="vacancy.announce"></div>
							<!-- <a href="{{vacancy.url}}" class="btn btn-primary btn-sm" translate>Send Application</a> -->
							<button class="btn btn-primary btn-sm" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseVacancyApp"  (click)="show_vacancie_form(vacancy.title);" translate >Send Application</button>
						</div>	
					</div>
					<div *ngIf="vacancies_page">
												
						<div class="collapse pt-4 show" id="collapseVacancyApp">

							<p translate>after_vacancies_text</p>

							<form class="form-anketa" method="POST" enctype="multipart/form-data" #castingISForm="ngForm" (ngSubmit)="onFormSubmitCasting(castingISForm)">
							
								<div class="form-group">
									<label translate>First Name</label>
									<input type="text" name="first_name" class="form-control" ngModel required #profileFirstName="ngModel">
									<ng-container *ngIf="first_name_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>
									<div class="clear_5"></div>
								</div>	
								<div class="form-group">
									<label translate>Last Name</label>
									<input type="text" name="last_name" class="form-control" ngModel required #profileLastName="ngModel">
									<ng-container *ngIf="last_name_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>
									<div class="clear_5"></div>
								</div>		
								<div class="form-group">
									<label translate>Surname</label>
									<input type="text" name="surname" class="form-control" ngModel required #profileSurname="ngModel">
									<ng-container *ngIf="surname_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>
									<div class="clear_5"></div>
								</div>	
								
								<div class="form-group">
									<label translate>Gender</label>
									<select class="form-control" name="gender" ngModel required #profileGender="ngModel">
										<option translate>Male</option>
										<option translate>Female</option>
									</select>	
									<ng-container *ngIf="gender_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>							
									<div class="clear_5"></div>
								</div>	
								
								<div class="form-group">
									<label translate>Date of Birth</label>
									<input type="date" name="birth" class="form-control" ngModel required #profileBirth="ngModel">
									<ng-container *ngIf="birth_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>
									<div class="clear_5"></div>
								</div>				
								<div class="form-group">
									<label translate>Country</label>
									<input type="text" name="country" class="form-control" ngModel required #profileCountry="ngModel">
									<ng-container *ngIf="country_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>
								</div>					
								<div class="form-group">
									<label translate>City</label>
									<input type="text" name="city" class="form-control" ngModel required #profileCity="ngModel">
									<ng-container *ngIf="city_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>
								</div>	
								<div class="form-group">
									<label>E-mail</label>
									<input type="email" name="email" class="form-control" ngModel required #profileEmail="ngModel">
									<ng-container *ngIf="email_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>
								</div>							
								<div class="form-group">
									<label translate>Phone</label>
									<input type="text" name="phone" class="form-control" [textMask]="{mask: phone_mask}" ngModel required #profilePhone="ngModel">
									<ng-container *ngIf="phone_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>
								</div>	
								
								<div class="form-group">
									<label translate>Education</label>
									<textarea name="education" class="form-control mt-2" rows="5" ngModel required #profileEducation="ngModel"></textarea>
									<ng-container *ngIf="education_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>							
								</div>							
								
								<div class="form-group">
									<label translate>Position</label>
									<input type="text" name="position" class="form-control" [(ngModel)]="profilePos" required #profilePosition="ngModel">
									<ng-container *ngIf="position_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>
								</div>
								<div class="form-group">
									<div class="form-check">
										<label class="form-check-label">
											<input type="checkbox" class="form-check-input" name="exp" value="1" [checked]="true" ngModel #profileExp="ngModel"><span translate>Is there any experience on TV</span>
										</label>
									</div>								
									<div class="clear_5"></div>
									<div class="clear_5"></div>
									<div class="clear_5"></div>
								</div>
								
								<div class="form-group">
									<label translate>English level</label>
									<select class="form-control" name="eng" ngModel required #profileEng="ngModel">
										<option value="no" translate>No</option> 
										<option value="elementary" translate>Elementary level</option> 
										<option value="average" translate>Average level</option> 
										<option value="high" translate>High level</option> 
										<option value="extended" translate>Extended level</option>
									</select>	
									<ng-container *ngIf="gender_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>							
									<div class="clear_5"></div>
								</div>								
								 
								<div class="form-group">
									<label translate>Last place of work</label>
									<textarea name="last_job" class="form-control mt-2" rows="5" ngModel required #profileLastjob="ngModel"></textarea>
									<ng-container *ngIf="last_job_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>							
								</div>
								
								<div class="form-group">
									<label translate>Hobby</label>
									<textarea name="hobby" class="form-control mt-2" rows="5" ngModel required #profileHobby="ngModel"></textarea>
									<ng-container *ngIf="hobby_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>							
								</div>							
								
								<div class="form-group">
									<label translate>Your photo (portrait)</label> (.jpg, .jpeg, .png)<br>
									<input type="file" accept=".jpg, .jpeg, .png" class="form-control-file" id="portrait" name="portrait" ngModel #profilePortrait="ngModel" (change)="onFileChange($event)">							
									<div class="clear_5"></div>
									<div class="clear_5"></div>
									<div class="clear_5"></div>
								</div>
								
								<div class="form-group">
									<label translate>Resume</label> (.doc, .pdf, .docx, .djvu)<br>
									<input type="file" accept=".doc, .pdf, .docx, .djvu" class="form-control-file" id="resume" name="resume" ngModel #profileResume="ngModel" (change)="onFileChange2($event)">							
									<div class="clear_5"></div>
									<div class="clear_5"></div>
									<div class="clear_5"></div>
								</div>							

								<div class="clear_5"></div>
								
								<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>Thank you, your application has been accepted. Expect a call from a manager for an invitation to a creative interview.</div>
								
								<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
								
								<div class="text-right">
									<button type="submit" class="tag-item big active" translate>Send Application</button>
								</div>
								
							</form>	
						</div>						
					</div>					
					<div *ngIf="is_out_scool_form">
						<form class="form-anketa" method="POST" enctype="multipart/form-data" #castingISForm="ngForm" (ngSubmit)="onFormSubmitScool(castingISForm)">
							<div class="form-group">
								<label translate>First Name</label>
								<input type="text" name="first_name" class="form-control" ngModel required #profileFirstName="ngModel">
								<ng-container *ngIf="first_name_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>
								<div class="clear_5"></div>
							</div>	
							<div class="form-group">
								<label translate>Last Name</label>
								<input type="text" name="last_name" class="form-control" ngModel required #profileLastName="ngModel">
								<ng-container *ngIf="last_name_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>
								<div class="clear_5"></div>
							</div>		
							<div class="form-group">
								<label translate>Surname</label>
								<input type="text" name="surname" class="form-control" ngModel required #profileSurname="ngModel">
								<ng-container *ngIf="surname_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>
								<div class="clear_5"></div>
							</div>	
							
							<div class="form-group">
								<label translate>Date of Birth</label>
								<input type="date" name="birth" class="form-control" ngModel required #profileBirth="ngModel">
								<ng-container *ngIf="birth_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>							
								<div class="clear_5"></div>
							</div>	
							
							<ng-container *ngIf="telemalysh == false">
								<div class="form-group">
									<label translate>Country</label>
									<input type="text" name="country" class="form-control" ngModel required #profileCountry="ngModel">
									<ng-container *ngIf="country_error">
										<div class="clear_5"></div>
										<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
									</ng-container>								
									<div class="clear_5"></div>
								</div>
							</ng-container>
								
							<div class="form-group">
								<label translate>City</label>
								<input type="text" name="city" class="form-control" ngModel required #profileCity="ngModel">
								<ng-container *ngIf="city_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>
							</div>
							
							<ng-container *ngIf="telemalysh">
								<div class="form-group">
									<label translate>Name of mom/dad</label>
									<input type="text" name="name_parents" class="form-control" ngModel required #profileSurname="ngModel">
								</div>
							</ng-container>
	
							<div class="form-group">
								
								<ng-container *ngIf="telemalysh">
									<label translate>Mom/Dad phone</label>
								</ng-container>

								<ng-container *ngIf="telemalysh == false">
									<label translate>Phone</label>
								</ng-container>

								<input type="text" name="phone" class="form-control" [textMask]="{mask: phone_mask}" ngModel required #profilePhone="ngModel">
								<ng-container *ngIf="phone_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>
							</div>
							
							<div class="form-group">
								<label>E-mail</label>
								<input type="email" name="email" class="form-control" ngModel required #profileEmail="ngModel">
								<ng-container *ngIf="email_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>
							</div>		
							<div class="form-group">
								<div class="form-check">
									<label class="form-check-label">
										<input type="checkbox" class="form-check-input" name="accept" value="1" [checked]="true" ngModel required #profileAccept="ngModel"><span translate>Do you agree to receive information on casting dates, class schedule by e-mail?</span>
									</label>
								</div>	
								<ng-container *ngIf="accept_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>
								<div class="clear_5"></div>
								<div class="clear_5"></div>
							</div>
							<div class="form-group">
								<label translate>Personal information</label> <br>(<span translate>Education, interests, links to social. networks and everything you would like to tell about yourself</span>)
								<textarea name="about" class="form-control mt-2" rows="5" ngModel required #profileAbout="ngModel"></textarea>
								<ng-container *ngIf="about_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>							
							</div>
							<div class="form-group">
								<label translate>Your photo (portrait)</label> (.jpg, .jpeg, .png)<br>
								<input type="file" accept=".jpg, .jpeg, .png" class="form-control-file" id="portrait" name="portrait" ngModel required #profilePortrait="ngModel" (change)="onFileChange($event)">
								<ng-container *ngIf="portrait_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>
								<div class="clear_5"></div>
								<div class="clear_5"></div>
							</div>
							<div class="form-group">
								<div class="form-check">
									<label class="form-check-label">
										<input type="checkbox" class="form-check-input" name="accept2" value="1" ngModel required #profileAccept2="ngModel"><span translate>I agree to the processing of my personal data and the publication of my data on the site</span>
									</label>
								</div>							
							</div>		
							<ng-container *ngIf="accept2_error">
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
							<div *ngIf="profile_form_wait" class="alert alert-wait" role="alert" translate>Please wait while your application is sent to managers</div>
							<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>Thank you, your application has been accepted. Expect a call from a manager for an invitation to a creative interview.</div>
							<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
							<div class="text-right">
								<button *ngIf="profile_form_submit_btn" type="submit" class="tag-item big active" translate>Send Application</button>
							</div>
						</form>	
					</div>
					<div *ngIf="playlists">
						<div id="project-playlists">
							<div *ngFor="let playlist of playlists">
								<div *ngIf="playlist">
									<div class="horizontal-widget">
										<h2 class="horizontal-widget-title">
											{{playlist.title}} <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
										</h2>
										<owl-carousel [options]="CarouselOptions2" [items]="playlist.items" [carouselClasses]="['standart_carousel_four']" >  
											<div class="item" *ngFor="let pl_item of playlist.items">  
												<a href="{{pl_item.url}}" class="animated-image">
													<img src="{{pl_item.img}}" class="w-100">
												</a>
												<a href="{{pl_item.url}}" class="section-widget-title">{{pl_item.title}}</a>
												<p class="section-widget-meta">{{pl_item.date}}</p>
												<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}">{{pl_item.project_name}}</a></p>
												<div class="clear_15 d-lg-none"></div> 
											</div>  
										</owl-carousel>							
										<div class="text-center d-md-none">
											<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
										</div>														
									</div>
									<div class="clear_40"></div>
								</div>
							</div>	
							<div class="text-center" *ngIf="all_videos_url">
								<a href="{{all_videos_url}}" class="section-widget-btn m-0" translate>More Videos</a>
							</div>	
						</div>
						<div class="clear_60"></div>
						
						<div *ngIf="horizontal_ads_2_img; else adsHorizontal2Template">
							<div class="text-center">
								<a href="{{horizontal_ads_2_url}}" target="_blank" rel="nofollow">
									<img src="{{horizontal_ads_2_img}}" class="w-100">
								</a>		
							</div>
							<div class="clear_60"></div>
						</div>
						<ng-template #adsHorizontal2Template>
							<div class="text-center">
								<div id="div-gpt-ad-1516984738506-1"></div>
							</div>
							<div class="clear_60"></div>
						</ng-template>							
						
					</div>
				</div>
				<div class="col-12 col-md-4">
					<app-sidebar></app-sidebar>	
				</div>			
			</div>
		</div>
	</section>
</ng-template>

<app-footer></app-footer>