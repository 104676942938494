import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm} from '@angular/forms';

@Component({
	selector: 'app-castings',
	templateUrl: './castings.component.html',
	styleUrls: ['./castings.component.css']
})
export class CastingsComponent implements OnInit {
	
	files: any;
	
	/**
	* Значения по умолчанию
	*/
	urlLanguage = '/ua';	
	
	profile_form_success = false;
	profile_form_danger = false;
	profile_form_wait = false;
	profile_form_submit_btn = true;
	fio_error = false;
	phone_error = false;
	email_error = false;
	birth_error = false;
	country_error = false;
	city_error = false;
	accept_error = false;
	accept2_error = false;
	portrait_error = false;	
	gender_error = false;	
	positionambit_error = false;

	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient
	) {}

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		
		if(lang == 'uk'){
			this.titleService.setTitle( 'Кастинги | Inter' );
		}else if(lang == 'ru'){
			this.titleService.setTitle(  'Кастинги | Inter' );
		}else{
			this.titleService.setTitle(  'Castings | Inter' );
		}
		
	}
	
	onFormSubmit(castingISForm: NgForm) {
		
		this.profile_form_success = false;
		this.profile_form_danger = false;
		this.profile_form_wait = true;
		this.profile_form_submit_btn = false;
		let body = castingISForm.value;
		let send_body = new FormData();
		
		let fio, phone, email, birth, country, city, accept, accept2, portrait, gender, positionambit;
		
		fio = '';
		phone = '';
		email = '';
		birth = '';
		country = '';
		city = '';
		accept = '';
		accept2 = '';
		portrait = '';
		gender = '';
		positionambit = '';
		
		if(body.fio != null){
			fio = encodeURIComponent(body.fio);
		}
		if(body.phone != null){
			phone = encodeURIComponent(body.phone);
		}
		if(body.email != null){
			email = encodeURIComponent(body.email);
		}
		if(body.birth != null){
			birth = encodeURIComponent(body.birth);
		}
		if(body.country != null){
			country = encodeURIComponent(body.country);
		}
		if(body.city != null){
			city = encodeURIComponent(body.city);
		}
		if(body.accept != null){
			accept = encodeURIComponent(body.accept);
		}
		if(body.accept2 != null){
			accept2 = encodeURIComponent(body.accept2);
		}		
		if(body.gender != null){
			gender = encodeURIComponent(body.gender);
		}		
		if(body.positionambit != null){
			positionambit = encodeURIComponent(body.positionambit);
		}		
		
		if(fio == ''){ this.fio_error = true; }else{ this.fio_error = false; }
		if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
		if(email == ''){ this.email_error = true; }else{ this.email_error = false; }
		if(birth == ''){ this.birth_error = true; }else{ this.birth_error = false; }
		if(country == ''){ this.country_error = true; }else{ this.country_error = false; }
		if(city == ''){ this.city_error = true; }else{ this.city_error = false; }
		if(accept2 == ''){ this.accept2_error = true; }else{ this.accept2_error = false; }
		if(gender == ''){ this.gender_error = true; }else{ this.gender_error = false; }
		if(positionambit == ''){ this.positionambit_error = true; }else{ this.positionambit_error = false; }

		if(fio == '' || phone == '' || email == '' || birth == '' || country == '' || city == '' || accept2 == '' || gender == '' || positionambit == ''){
			this.profile_form_success = false;
			this.profile_form_danger = true;
			this.profile_form_wait = false;
			this.profile_form_submit_btn = true;
			return;
		}
		
		send_body.append('fio', fio);
		send_body.append('phone', phone);
		send_body.append('email', email);
		send_body.append('birth', birth);
		send_body.append('country', country);
		send_body.append('city', city);
		send_body.append('accept', accept);
		send_body.append('gender', gender);
		send_body.append('positionambit', positionambit);
		
		if(this.files && this.files.length > 0){
			for (let i = 0; i < this.files.length; i++) {
				send_body.append('portrait', this.files[i]);
			}		
		}
		
		let api_url = '//dashboard.inter.ua/api/anketa_all_form';
		this.http.post(api_url, send_body).subscribe(data => {
            if(data != undefined){
				this.profile_form_success = true;
				this.profile_form_danger = false;
				castingISForm.reset();
			}else{
				this.profile_form_success = false;
				this.profile_form_danger = true;				
			}
			this.profile_form_wait = false;	
			this.profile_form_submit_btn = true;
        });	
		
	}	
	
	onFileChange(event) {
		let target = event.target || event.srcElement;
		this.files = target.files;
	}

}
