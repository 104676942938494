<app-header></app-header>

<section>
	<div class="container py-5">
		
		<div class="row">
			<div class="col-12">
				<h2 class="article-title" translate>Video</h2>
			</div>		
		</div>
		
		<div class="clear_15 d-none d-md-block"></div>
		
		<div class="row">
		
			<div class="col-12 col-md-8">
				
				<div class="video-card">
					
					<div *ngIf="videoCode">
						<iframe *ngIf="url" width="100%" height="600" [src]="url" frameborder="0" scrolling="no" allowfullscreen></iframe>
					</div>
					
					<div class="row">
						<div class="col-12 col-md-7">
							<div class="clear_15"></div>
							<h1 [innerHTML]="title"></h1>
							<p class="section-widget-meta">{{date}}</p>
							<p class="section-widget-meta" *ngIf="ch_name"><span translate>Channel</span>: <a href="{{ch_url}}" [innerHTML]="ch_name"></a></p>	
							<div class="video-card-description" [innerHTML]="body"></div>						
						</div>
						<div class="col-12 col-md-5">
							<div class="bg-playlist">
								<h3 translate>See also</h3>
								<div *ngIf="current_playlist">
									<div *ngFor="let current_pl of current_playlist">
										<div *ngIf="current_pl.items">
											<div class="now-item" *ngFor="let current_playlist_item of current_pl.items">
												<div class="row px-2">
													<div class="col-4 px-2">
														<a href="{{current_playlist_item.url}}"><img src="{{current_playlist_item.img}}" class="w-100"></a>
													</div>
													<div class="col-8 px-2">
														<a href="{{current_playlist_item.url}}" [innerHTML]="current_playlist_item.title"></a>
													</div>									
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>						
					</div>
				</div>

				<div class="clear_40"></div>
				
				<div *ngIf="playlists">
				
					<div id="project-playlists">
						
						<div *ngFor="let playlist of playlists">
							
							<div *ngIf="playlist">
								<div class="horizontal-widget">
									<h2 class="horizontal-widget-title">
										<span [innerHTML]="playlist.title"></span> <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</h2>
									<div *ngIf="playlist.items">
										<owl-carousel [options]="CarouselOptions" [items]="playlist.items" [carouselClasses]="['standart_carousel_four']" >  
											<div class="item" *ngFor="let pl_item of playlist.items">  
												<a href="{{pl_item.url}}" class="animated-image">
													<img src="{{pl_item.img}}" class="w-100">
												</a>
												<a href="{{pl_item.url}}" class="section-widget-title" [innerHTML]="pl_item.title"></a>
												<p class="section-widget-meta">{{pl_item.date}}</p>
												<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}" [innerHTML]="pl_item.project_name"></a></p>
												<div class="clear_15 d-lg-none"></div> 
											</div>  
										</owl-carousel>	
									</div>
									<div class="text-center d-md-none">
										<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</div>									
								</div>
								<div class="clear_40"></div>
							</div>
							
						</div>
						
					</div>
				</div>	

				<div *ngIf="custom_playlists">
				
					<div id="project-playlists">
						
						<div *ngFor="let playlist of custom_playlists">
							
							<div *ngIf="playlist">
								<div class="horizontal-widget">
									<h2 class="horizontal-widget-title">
										<span [innerHTML]="playlist.title"></span> <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</h2>
									<div *ngIf="playlist.items">
										<owl-carousel [options]="CarouselOptions" [items]="playlist.items" [carouselClasses]="['standart_carousel_four']" >  
											<div class="item" *ngFor="let pl_item of playlist.items">  
												<a href="{{pl_item.url}}" class="animated-image">
													<img src="{{pl_item.img}}" class="w-100">
												</a>
												<a href="{{pl_item.url}}" class="section-widget-title" [innerHTML]="pl_item.title"></a>
												<p class="section-widget-meta">{{pl_item.date}}</p>
												<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}" [innerHTML]="pl_item.project_name"></a></p>
												<div class="clear_15 d-lg-none"></div> 
											</div>  
										</owl-carousel>	
									</div>
									<div class="text-center d-md-none">
										<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</div>									
								</div>
								<div class="clear_40"></div>
							</div>
							
						</div>
						
					</div>
				</div>				
				
				<div class="clear_15"></div>
				
				<div *ngIf="horizontal_ads_2_img; else adsHorizontal2Template">
					<a href="{{horizontal_ads_2_url}}" target="_blank" rel="nofollow">
						<img src="{{horizontal_ads_2_img}}" class="w-100">
					</a>		
					<div class="clear_60"></div>
				</div>
				<ng-template #adsHorizontal2Template>
					<div class="text-center">
						<div id="div-gpt-ad-1516984738506-1"></div>
					</div>
					<div class="clear_60"></div>
				</ng-template>				
				
				<!--BODY TABOOLA Below Article Thumbnails-->
				<div id="taboola-below-article-thumbnails"></div>
				
				<div class="clear_60"></div>	
				
			</div>
			
			<div class="col-12 col-md-4">		
				<app-sidebar></app-sidebar>				
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>