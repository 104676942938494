<app-header></app-header>

<section>
	<div class="container py-5">	
		<h1 class="article-title" translate>Interschool</h1>
		<div class="clear_15"></div>
		<div class="row">
			<div class="col-12 col-md-8">

				<div class="videoteka-tags">
					<a class="tag-item" href="{{ urlLanguage }}/interschool" translate>About Interschool</a> 
					<a class="tag-item" href="{{ urlLanguage }}/interschool-specialty" translate>Media Specializations</a> 
					<a class="tag-item" href="{{ urlLanguage }}/interschool-partners" translate>Partners</a> 
					<a class="tag-item" href="{{ urlLanguage }}/news/category/news" translate>News</a> 
					<a class="tag-item" href="{{ urlLanguage }}/interschool-coordinates" translate>Contacts</a> 
					<a class="tag-item active" href="{{ urlLanguage }}/interschool-casting" translate>Apply</a> 
				</div>		
				<div class="clear_40"></div>				
		
				<article>
					<form class="form-anketa" method="POST" enctype="multipart/form-data" #castingISForm="ngForm" (ngSubmit)="onFormSubmit(castingISForm)">
						<div class="form-group">
							<label translate>Please choose a specialization</label>
							<select class="form-control" name="spec" ngModel required #profileSpec="ngModel" (change)="onSpecChange($event.target.value)">
								<option translate>Journalist</option>
								<option translate>Screenwriter</option>
								<option translate>Producer</option>
								<option translate>Operator</option>
								<option translate>Director</option>
								<option translate>Sound engineer</option>
								<option translate>TV presenter</option>
								<option translate>Film editor</option>
								<option translate>Second director</option>
								<option translate>TV baby</option>
								<option translate>Mediastart</option>
							</select>	
							<ng-container *ngIf="spec_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>							
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<label translate>First Name</label>
							<input type="text" name="first_name" class="form-control" ngModel required #profileFirstName="ngModel">
							<ng-container *ngIf="first_name_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>	
						<div class="form-group">
							<label translate>Last Name</label>
							<input type="text" name="last_name" class="form-control" ngModel required #profileLastName="ngModel">
							<ng-container *ngIf="last_name_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>		
						<div class="form-group">
							<label translate>Surname</label>
							<input type="text" name="surname" class="form-control" ngModel required #profileSurname="ngModel">
							<ng-container *ngIf="surname_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>							
						<div class="form-group">
							<label translate>Date of Birth</label>
							<input type="date" name="birth" class="form-control" ngModel required #profileBirth="ngModel">
							<ng-container *ngIf="birth_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>							
							<div class="clear_5"></div>
						</div>
						
						<ng-container *ngIf="telemalysh == false">			
							<div class="form-group">
								<label translate>Country</label>
								<input type="text" name="country" class="form-control" ngModel required #profileCountry="ngModel">
								<ng-container *ngIf="country_error">
									<div class="clear_5"></div>
									<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
								</ng-container>								
								<div class="clear_5"></div>
							</div>	
						</ng-container>	

						<div class="form-group">
							<label translate>City</label>
							<input type="text" name="city" class="form-control" ngModel required #profileCity="ngModel">
							<ng-container *ngIf="city_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>
						
						<ng-container *ngIf="telemalysh">
							<div class="form-group">
								<label translate>Name of mom/dad</label>
								<input type="text" name="name_parents" class="form-control" ngModel required #profileSurname="ngModel">
							</div>
						</ng-container>

						<div class="form-group">
							
							<ng-container *ngIf="telemalysh">
								<label translate>Mom/Dad phone</label>
							</ng-container>

							<ng-container *ngIf="telemalysh == false">
								<label translate>Phone</label>
							</ng-container>

							<input type="text" name="phone" class="form-control" [textMask]="{mask: phone_mask}" ngModel required #profilePhone="ngModel">
							<ng-container *ngIf="phone_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>	
						<div class="form-group">
							<label>E-mail</label>
							<input type="email" name="email" class="form-control" ngModel required #profileEmail="ngModel">
							<ng-container *ngIf="email_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>		
						<div class="form-group">
							<div class="form-check">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" name="accept" value="1" ngModel required #profileAccept="ngModel"><span translate>Do you agree to receive information on casting dates, class schedule by e-mail?</span>
								</label>
							</div>	
							<ng-container *ngIf="accept_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
							<div class="clear_5"></div>
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<label translate>Personal information</label> <br>(<span translate>Education, interests, links to social. networks and everything you would like to tell about yourself</span>)
							<textarea name="about" class="form-control mt-2" rows="5" ngModel required #profileAbout="ngModel"></textarea>
							<ng-container *ngIf="about_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>							
						</div>
						<div class="form-group">
							<label translate>Your photo (portrait)</label> (.jpg, .jpeg, .png)<br>
							<input type="file" accept=".jpg, .jpeg, .png" class="form-control-file" id="portrait" name="portrait" ngModel required #profilePortrait="ngModel" (change)="onFileChange($event)">
							<ng-container *ngIf="portrait_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
							<div class="clear_5"></div>
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" name="accept2" value="1" ngModel required #profileAccept2="ngModel"><span translate>I agree to the processing of my personal data and the publication of my data on the site</span>
								</label>
							</div>							
						</div>		
						<ng-container *ngIf="accept2_error">
							<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
						</ng-container>
						<div class="clear_5"></div>

						<div *ngIf="profile_form_wait" class="alert alert-wait" role="alert" translate>Please wait while your application is sent to managers</div>
						
						<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>Thank you, your application has been accepted. Expect a call from a manager for an invitation to a creative interview.</div>
						<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
						<div class="text-right">
							<button *ngIf="profile_form_submit_btn" type="submit" class="tag-item big active" translate>Send Application</button>
						</div>
					</form>	
				</article>
						
				<div class="clear_40"></div>		
				
			</div>
			<div class="col-12 col-md-4">
				<app-sidebar></app-sidebar>	
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>