<app-header></app-header>

<section>
	<div class="container py-5">
		<h1 class="article-title" translate>TV Projects</h1>
		<div class="row">
			<div class="col-12 col-md-8">
				<div class="row p-0 m-0">
					<div *ngFor="let item of items" class="col-12 col-md-4 p-0 m-0">
						<a href="{{item.url}}" class="animated-image" title="{{item.title}}"><img src="{{item.image}}" class="w-100" title="{{item.title}}" alt="{{item.title}}"></a>	
					</div>
				</div>	
				<div class="clear_60"></div>
				<div *ngIf="playlists">
					<div id="project-playlists">
						<div *ngFor="let playlist of playlists">
							<div *ngIf="playlist">
								<div class="horizontal-widget">
									<h2 class="horizontal-widget-title">
										{{playlist.title}} <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</h2>
									<owl-carousel [options]="CarouselOptions" [items]="playlist.items" [carouselClasses]="['standart_carousel_four']" >  
										<div class="item" *ngFor="let pl_item of playlist.items">  
											<a href="{{pl_item.url}}" class="animated-image">
												<img src="{{pl_item.img}}" class="w-100">
											</a>
											<a href="{{pl_item.url}}" class="section-widget-title">{{pl_item.title}}</a>
											<p class="section-widget-meta">{{pl_item.date}}</p>
											<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}">{{pl_item.project_name}}</a></p>
											<div class="clear_15 d-lg-none"></div> 
										</div>  
									</owl-carousel>							
									<div class="text-center d-md-none">
										<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</div>														
								</div>
								<div class="clear_40"></div>
							</div>
						</div>	
					</div>
					<div class="clear_60"></div>
				</div>				
			</div>
			<div class="col-12 col-md-4">		
				<app-sidebar></app-sidebar>				
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>