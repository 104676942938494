<app-header></app-header>

<section>
	<div class="container py-5">	
		<h1 class="article-title" translate>Satellite</h1>
		<div class="clear_15"></div>
		<div class="row">
			<div class="col-12 col-md-8">
				
				<div class="videoteka-tags">
					<a class="tag-item" href="{{ urlLanguage }}/about" translate>About</a> 
					<!--
					<a class="tag-item" href="{{ urlLanguage }}/about/rating" translate>Rating</a> 
					-->
					<a class="tag-item" target="_blank" href="{{ urlLanguage }}/news/category/awards" translate>Awards</a> 
					<a class="tag-item" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a>
					<a class="tag-item" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a> 
					<a class="tag-item active" href="{{ urlLanguage }}/sputnik" translate>Satellite</a> 
					<a class="tag-item" href="{{ urlLanguage }}/castings" translate>Castings</a> 

					<a class="tag-item" href="http://інтер.укр" target="_blank" rel="nofollow" translate>Ownership structure</a>
					
				</div>		

				<div class="clear_40"></div>
							
				<article>
					<p translate>Dear viewers!</p>
					<ul>
						<li translate>parameter1</li>
						<li translate>parameter2</li>
						<li translate>parameter3</li>
						<li translate>parameter4</li>
						<li translate>parameter5</li>
						<li translate>parameter6</li>
						<li translate>parameter7</li>
						<li translate>parameter8</li>
					</ul>
					<p translate>If you were not able to independently reconfigure</p>
					<p><strong>0800 508 650</strong></p>	
				</article>
				
				<div class="clear_40"></div>	

				<div class="row align-items-center">
					<div class="col-12 col-md-6">
						<div class="video-box-iframe">
							<video src="http://inter.ua/uploads/video/2016/02/16/809b56253875b07d5b53d8395448391bb11cebc2.mp4" class="w-100" controls></video>
						</div>
					</div>
					<div class="col-12 col-md-6 text-center">
						<h3 translate>Download satellite signal tuning instructions:</h3>
						<div class="clear_15"></div>
						<a class="tag-item big m-0" href="assets/img/SPUTNIK_slaydi.pdf" target="_blank" translate>DOWNLOAD</a>
					</div>
				</div>				

			</div>
			<div class="col-12 col-md-4">
				<app-sidebar></app-sidebar>	
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>