import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

interface VcategoryInterface {
	title: string;
	playlists: any;
	first_video: any;
	vcategories: any;
}

@Component({
	selector: 'app-project',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.css']
})

export class CategoryComponent implements OnInit{
	
	/**
	* Значения по умолчанию
	*/
	urlLanguage = '/ua';	
	title = '';
	playlists = false;
	first_video = false;
	vcategories = false;
	
	url: SafeResourceUrl;
	
	CarouselOptions2 = {
		responsiveClass: true,
		items: 3,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 3
			}
		}		
	};	
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient,
		public sanitizer:DomSanitizer
	) {}
	
	ngOnInit() {	
	
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}				
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}		
		
		if(slug != '' && slug != undefined){
			var api_url = '//dashboard.inter.ua/api/vcategory/' + slug + '/' + lang;	
			this.http.get<VcategoryInterface>(api_url).subscribe(data => {
				this.title = data.title;
				
				if(this.title == '' || this.title == undefined){
					if(lang == 'uk'){
						this.router.navigate(['ua']);
					}else if(lang == 'ru'){
						this.router.navigate(['ru']);
					}if(lang == 'en'){
						this.router.navigate(['en']);
					}
				}				
				
				this.playlists = data.playlists;
				this.first_video = data.first_video;
				if(this.first_video){
					this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.first_video[0].video_code);
				}
				this.vcategories = data.vcategories;
				this.titleService.setTitle( this.title + ' | Inter' );
			});						
		}else{
			this.router.navigate(['error404']);
		}	
		
	}		

}