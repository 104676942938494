import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm} from '@angular/forms';

@Component({
	selector: 'app-all-for-you-casting',
	templateUrl: './all-for-you-casting.component.html',
	styleUrls: ['./all-for-you-casting.component.css']
})
export class AllForYouCastingComponent implements OnInit {
	
	files: any;
	
	/**
	* Значения по умолчанию
	*/
	urlLanguage = '/ua';	
	
	profile_form_success = false;
	profile_form_danger = false;
	profile_form_wait = false;
	profile_form_submit_btn = true;
	
	couple_photo_error = false;
	
	fio_error = false;
	phone_error = false;
	social_link_error = false;
	age_error = false;


	fio_partner_error = false;
	social_link_partner_error = false;
	
	accept2_error = false;

	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient
	) {}

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		
		if(lang == 'uk'){
			this.titleService.setTitle( 'Кастинги | Inter' );
		}else if(lang == 'ru'){
			this.titleService.setTitle(  'Кастинги | Inter' );
		}else{
			this.titleService.setTitle(  'Castings | Inter' );
		}
		
	}
	
	onFormSubmit(castingISForm: NgForm) {
		
		this.profile_form_success = false;
		this.profile_form_danger = false;
		this.profile_form_wait = true;
		this.profile_form_submit_btn = false;
		let body = castingISForm.value;
		let send_body = new FormData();
		
		let fio, phone, social_link, age, fio_partner, social_link_partner, accept2;
		
		fio = '';
		phone = '';
		social_link = '';
		age = '';

		fio_partner = '';
		social_link_partner = '';
		
		accept2 = '';
		
		
		if(body.fio != null){
			fio = encodeURIComponent(body.fio);
		}
		if(body.phone != null){
			phone = encodeURIComponent(body.phone);
		}
		if(body.social_link != null){
			social_link = encodeURIComponent(body.social_link);
		}
		if(body.age != null){
			age = encodeURIComponent(body.age);
		}
		if(body.accept2 != null){
			accept2 = encodeURIComponent(body.accept2);
		}

		if(body.fio_partner != null){
			fio_partner = encodeURIComponent(body.fio_partner);
		}
		if(body.social_link_partner != null){
			social_link_partner = encodeURIComponent(body.social_link_partner);
		}
		
		if(fio == ''){ this.fio_error = true; }else{ this.fio_error = false; }
		if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
		if(social_link == ''){ this.social_link_error = true; }else{ this.social_link_error = false; }
		if(age == ''){ this.age_error = true; }else{ this.age_error = false; }

		if(fio_partner == ''){ this.fio_partner_error = true; }else{ this.fio_partner_error = false; }
		if(social_link_partner == ''){ this.social_link_partner_error = true; }else{ this.social_link_partner_error = false; }
		
		if(accept2 == ''){ this.accept2_error = true; }else{ this.accept2_error = false; }

		
		if( 
			fio == '' || phone == '' || social_link == '' || age == '' || accept2 == '' || fio_partner == '' || social_link_partner == '' ){
			this.profile_form_success = false;
			this.profile_form_danger = true;
			this.profile_form_wait = false;
			this.profile_form_submit_btn = true;
			return;
		}
		
		send_body.append('fio', fio);
		send_body.append('phone', phone);
		send_body.append('social_link', social_link);
		send_body.append('age', age);
		send_body.append('fio', fio_partner);
		send_body.append('social_link', social_link_partner);
		send_body.append('accept', accept2);

		if(this.files && this.files.length > 0){
			for (let i = 0; i < this.files.length; i++) {
				send_body.append('couple_photo', this.files[i]);
			}		
		}
		
		let api_url = '//dashboard.inter.ua/api/anketa_all_for_you';
		this.http.post(api_url, send_body).subscribe(data => {
            if(data != undefined){
				this.profile_form_success = true;
				this.profile_form_danger = false;
				castingISForm.reset();
			}else{
				this.profile_form_success = false;
				this.profile_form_danger = true;				
			}
			this.profile_form_wait = false;	
			this.profile_form_submit_btn = true;
        });	
		
	}	
	
	onFileChange(event) {
		let target = event.target || event.srcElement;
		this.files = target.files;
	}

}
