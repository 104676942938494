<app-header></app-header>


<section>
	<div class="container py-5">	
		<h1 class="article-title">{{ title }}</h1>
		<div class="clear_15"></div>
		<div class="row">
			<div class="col-12 col-md-8">
			
				<div *ngIf="vcategories">
					<ul class="videoteka-tags compact">
						<li><a class="tag-item" href="{{ urlLanguage }}/video" translate>All</a></li>
						<li *ngFor="let vcategory of vcategories">
							<a *ngIf="vcategory.title == title; else noactiveTemplate" class="tag-item active" href="{{vcategory.url}}">{{vcategory.title}}</a> 
							<ng-template #noactiveTemplate>
								<a class="tag-item" href="{{vcategory.url}}" #noactiveTemplate>{{vcategory.title}}</a>
							</ng-template>
						</li>
					</ul>
					<div class="clear_30"></div>
				</div>
				
				<div *ngIf="first_video">
					<div class="videos-page-top">
						<div class="row" *ngFor="let current_first_video of first_video">
							<div class="col-12 col-md-6">
								<div class="video-box-iframe">
									<iframe *ngIf="url" width="100%" height="600" [src]="url" frameborder="0" scrolling="no" allowfullscreen></iframe>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<h2>{{current_first_video.title}}</h2>
								<p class="section-widget-meta">{{current_first_video.date}}</p>
								<div class="clear_15"></div>
								<p [innerHTML]="current_first_video.announce"></p>		
								<div class="horizontal-widget-more">
									<a href="{{current_first_video.url}}"><span translate>More</span> <img src="assets/img/gray_arrow_right.svg"></a>
								</div>							
							</div>
						</div>
					</div>
					<div class="clear_40"></div>
				</div>				
				<div *ngIf="playlists">
					<div id="project-playlists">
						<div *ngFor="let playlist of playlists">
							<div *ngIf="playlist">
								<div class="horizontal-widget">
									<h2 class="horizontal-widget-title">
										{{playlist.title}} <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</h2>
									<owl-carousel [options]="CarouselOptions2" [items]="playlist.items" [carouselClasses]="['standart_carousel_four']" >  
										<div class="item" *ngFor="let pl_item of playlist.items">  
											<a href="{{pl_item.url}}" class="animated-image">
												<img src="{{pl_item.img}}" class="w-100">
											</a>
											<a href="{{pl_item.url}}" class="section-widget-title">{{pl_item.title}}</a>
											<p class="section-widget-meta">{{pl_item.date}}</p>
											<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}">{{pl_item.project_name}}</a></p>
											<div class="clear_15 d-lg-none"></div> 
										</div>  
									</owl-carousel>							
									<div class="text-center d-md-none">
										<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</div>														
								</div>
								<div class="clear_40"></div>
							</div>
						</div>	
					</div>
					<div class="clear_60"></div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<app-sidebar></app-sidebar>	
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>