import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

interface VideoInterface {
	title: string;
	body: string;
	img: any;
	date: string;
	videoCode: any;
	videoURL: any;
	playlists: any;
	custom_playlists: any;
	current_playlist: any;
	ch_name: string;
	ch_url: string;
	horizontal_ads_2_img: string;
	horizontal_ads_2_url: string;
	horizontal_code_2: string;
}

@Component({
	selector: 'app-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.css']
})

export class VideoComponent implements OnInit {
	
	urlLanguage = '/ua';
	title = '';
	body = '';
	img = '';	
	date = '';	
	videoCode = null;
	videoURL = null;
	playlists = false;
	custom_playlists = false;
	current_playlist = false;
	ch_name = '';
	ch_url = '';
	source: string = '';
	url: SafeResourceUrl;
	horizontal_ads_2_img = '';
	horizontal_ads_2_url = '';
	horizontal_code_2 = '';
	
	CarouselOptions = {
		responsiveClass: true,
		items: 3,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 3
			}
		}			
	};	
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient,
		public sanitizer:DomSanitizer
	) { }	

	ngOnInit() {	
		
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}			
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		var api_url = '//dashboard.inter.ua/api/video/' + slug + '/' + lang;	
		this.http.get<VideoInterface>(api_url).subscribe(data => {
			this.title = data.title;
			
			if(this.title == '' || this.title == undefined){
				if(lang == 'uk'){
					this.router.navigate(['ua']);
				}else if(lang == 'ru'){
					this.router.navigate(['ru']);
				}if(lang == 'en'){
					this.router.navigate(['en']);
				}
			}			
			
			this.body = data.body;
			this.img = data.img;
			this.date = data.date;
			this.playlists = data.playlists;
			this.custom_playlists = data.custom_playlists;
			this.videoCode = data.videoCode;
			this.ch_name = data.ch_name;
			this.current_playlist = data.current_playlist;
			this.ch_url = data.ch_url;
			this.horizontal_ads_2_img = data.horizontal_ads_2_img;
			this.horizontal_ads_2_url = data.horizontal_ads_2_url;
			this.horizontal_code_2 = data.horizontal_code_2;
			//this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoCode);
			this.videoURL = data.videoURL;
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoURL);
			
			this.titleService.setTitle( this.title + ' | Inter' );		
			
		});	
		
	}

}
