import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';

interface SidebarInterface {
	sidebar_ads_1_url: string;
	sidebar_ads_1_img: string;
	sidebar_code_1: string;
	sidebar_ads_2_url: string;
	sidebar_ads_2_img: string;
	sidebar_code_2: string;
	sidebar_ads_3_url: string;
	sidebar_ads_3_img: string;	
	sidebar_code_3: string;
	news_items: any;
	video_items: any;
}

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

	urlLanguage = '/ua';
	sidebar_ads_1_url = '';
	sidebar_ads_1_img = '';
	sidebar_code_1 = '';
	sidebar_ads_2_url = '';
	sidebar_ads_2_img = '';
	sidebar_code_2 = '';
	sidebar_ads_3_url = '';
	sidebar_ads_3_img = '';	
	sidebar_code_3 = '';
	news_items = false;
	video_items = false;
	
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient
	) { }	

	ngOnInit() {  

		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		
		var api_url = '//dashboard.inter.ua/api/sidebar/' + lang;
		
		this.http.get<SidebarInterface>(api_url).subscribe(data => {
			this.sidebar_ads_1_url = data.sidebar_ads_1_url;
			this.sidebar_ads_1_img = data.sidebar_ads_1_img;
			this.sidebar_code_1 = data.sidebar_code_1;
			this.sidebar_ads_2_url = data.sidebar_ads_2_url;
			this.sidebar_ads_2_img = data.sidebar_ads_2_img;
			this.sidebar_code_2 = data.sidebar_code_2;
			this.sidebar_ads_3_url = data.sidebar_ads_3_url;
			this.sidebar_ads_3_img = data.sidebar_ads_3_img;	
			this.sidebar_code_3 = data.sidebar_code_3;
			this.news_items = data.news_items;
			this.video_items = data.video_items;
		});
		
	}
	
}
