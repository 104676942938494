import { Component, AfterViewInit, Input } from "@angular/core"

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html'
})

export class BannerComponent implements AfterViewInit {
	
	slot = 'SLDS_Inter_Premium1';
	
	constructor() {}
	
	ngAfterViewInit() {
		setTimeout(() => {
			try {				
			
				
			  
			} catch (e) {
				console.error(e);
			}
		}, 2000);
	} 
	
}