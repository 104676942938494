import { Component, OnInit } from '@angular/core';
import { MatomoInjector } from 'ngx-matomo';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
	
	constructor( 
		private matomoInjector: MatomoInjector 
	){
		this.matomoInjector.init('//analytics.cosmonova.net/', 38);
	}
	
	title = 'inter';

	
	ngOnInit() { }
	
}
