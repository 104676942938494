import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	
	urlLanguage = '/ua';
	thisURLua = '/ua';
	thisURLru = '/ru';
	thisURLen = '/en';
	
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService
	) { }	

	ngOnInit() {
		
		var this_url = this.router.url;
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}

		switch(lang){
			case 'ua':
				if(this_url.indexOf('ua') === -1){
					this.thisURLua = this_url;
					this.thisURLru = '/ru';
					this.thisURLen = '/en';											
				}else{
					this.thisURLua = this_url;
					this.thisURLru = this_url.replace('/ua', '/ru');
					this.thisURLen = this_url.replace('/ua', '/en');						
				}
			break;
			case 'ru':
				this.thisURLru = this_url;
				this.thisURLua = this_url.replace('/ru', '/ua');
				this.thisURLen = this_url.replace('/ru', '/en');				
			break;
			case 'en':
				this.thisURLen = this_url;	
				this.thisURLua = this_url.replace('/en', '/ua');
				this.thisURLru = this_url.replace('/en', '/ru');				
			break;
			default:
				this.thisURLen = this_url;
				this.thisURLua = this_url.replace('/en', '/ua');
				this.thisURLru = this_url.replace('/en', '/ru');			
			break;
		}
		
		this.translate.use(lang);
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
	}

}
