import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm} from '@angular/forms';

@Component({
	selector: 'app-casting-interscool',
	templateUrl: './casting-interscool.component.html',
	styleUrls: ['./casting-interscool.component.css']
})

export class CastingInterscoolComponent implements OnInit {
	
	files: any;
	
	/**
	* Значения по умолчанию
	*/
	urlLanguage = '/ua';	
	
	profile_form_success = false;
	profile_form_danger = false;
	profile_form_wait = false;
	profile_form_submit_btn = true;
	fio_error = false;
	first_name_error = false;
	last_name_error = false;
	surname_error = false;
	phone_error = false;
	email_error = false;
	spec_error = false;
	birth_error = false;
	country_error = false;
	city_error = false;
	accept_error = false;
	accept2_error = false;
	portrait_error = false;
	about_error = false;
	telemalysh = false;

	public phone_mask = ['+', '3', '8', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient
	) {}

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		
		if(lang == 'uk'){
			this.titleService.setTitle( 'Інтершкола | Inter' );
		}else if(lang == 'ru'){
			this.titleService.setTitle(  'Интершкола | Inter' );
		}else{
			this.titleService.setTitle(  'Interscool | Inter' );
		}
		
	}	
	
	onFormSubmit(castingISForm: NgForm) {
			
		this.profile_form_success = false;
		this.profile_form_danger = false;
		this.profile_form_wait = true;
		this.profile_form_submit_btn = false;
		let body = castingISForm.value;
		console.log(body);
		let send_body = new FormData();
		
		let first_name, last_name, surname, phone, email, spec, birth, country, city, accept, accept2, portrait, about, name_parents;
		
		first_name = '';
		last_name = '';
		surname = '';
		about = '';
		phone = '';
		email = '';
		spec = '';
		birth = '';
		country = '';
		city = '';
		name_parents = '';
		accept = true;
		accept2 = false;
		portrait = '';

		if(body.about != null){
			about = encodeURIComponent(body.about);
		}		
		if(body.first_name != null){
			first_name = encodeURIComponent(body.first_name);
		}
		if(body.last_name != null){
			last_name = encodeURIComponent(body.last_name);
		}	
		if(body.surname != null){
			surname = encodeURIComponent(body.surname);
		}		
		if(body.phone != null){
			phone = encodeURIComponent(body.phone);
		}
		if(body.email != null){
			email = encodeURIComponent(body.email);
		}
		if(body.spec != null){
			spec = encodeURIComponent(body.spec);
		}
		if(body.birth != null){
			birth = encodeURIComponent(body.birth);
		}
		if(body.country != null){
			country = encodeURIComponent(body.country);
		}
		if(body.city != null){
			city = encodeURIComponent(body.city);
		}

		if(body.name_parents != null){
			name_parents = encodeURIComponent(body.name_parents);
		}
		
		if(body.accept != null){
			accept = body.accept;
		}else{
			accept = false;
		}
		if(body.accept2 != null){
			accept2 = body.accept2;
		}else{
			accept2 = false;
		}
		
		if(first_name == ''){ this.first_name_error = true; }else{ this.first_name_error = false; }
		if(last_name == ''){ this.last_name_error = true; }else{ this.last_name_error = false; }
		if(surname == ''){ this.surname_error = true; }else{ this.surname_error = false; }
		if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
		if(email == ''){ this.email_error = true; }else{ this.email_error = false; }
		if(spec == ''){ this.spec_error = true; }else{ this.spec_error = false; }
		if(birth == ''){ this.birth_error = true; }else{ this.birth_error = false; }
		
		//if(country == ''){ this.country_error = true; }else{ this.country_error = false; }
		
		if(city == ''){ this.city_error = true; }else{ this.city_error = false; }
		if(accept2 == false){ this.accept2_error = true; }else{ this.accept2_error = false; }
		if(about == ''){ this.about_error = true; }else{ this.about_error = false; }

		if(first_name == '' || last_name == '' || about == '' || surname == '' || phone == '' || email == '' || spec == '' || birth == '' || city == '' || accept2 == false){
			this.profile_form_success = false;
			this.profile_form_danger = true;
			this.profile_form_wait = false;
			this.profile_form_submit_btn = true;
			return;
		}
		
		send_body.append('first_name', first_name);
		send_body.append('last_name', last_name);
		send_body.append('surname', surname);
		send_body.append('phone', phone);
		send_body.append('email', email);
		send_body.append('spec', spec);
		send_body.append('birth', birth);
		send_body.append('country', country);
		send_body.append('city', city);
		send_body.append('accept', accept);
		send_body.append('about', about);
		send_body.append('name_parents', name_parents);
		
		if(this.files && this.files.length > 0){
			for (let i = 0; i < this.files.length; i++) {
				send_body.append('portrait', this.files[i]);
			}		
		}
		
		let api_url = '//dashboard.inter.ua/api/anketa_is_form';
		this.http.post(api_url, send_body).subscribe(data => {
            if(data != undefined){
				this.profile_form_success = true;
				this.profile_form_danger = false;
				castingISForm.reset();
			}else{
				this.profile_form_success = false;
				this.profile_form_danger = true;
						
			}
			this.profile_form_wait = false;	
			this.profile_form_submit_btn = true;
        });	
		
	}	
	
	onFileChange(event) {
		let target = event.target || event.srcElement;
		this.files = target.files;
	}


	onSpecChange( target_val ){
		this.telemalysh = false;
		console.log(target_val)
		if( 
			target_val == 'Телемалятко' || 
			target_val =='Телемалыш' || 
			target_val =='TV baby' ||
			target_val == 'Медіастарт' || 
			target_val =='Медиастарт' || 
			target_val =='Mediastart'
		 ){
			this.telemalysh = true;
		}
	}

}
