import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm} from '@angular/forms';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
import { NgStyle } from '@angular/common';

interface ProjectInterface {
	title: string;
	content: string;
	img: string;
	image_slide: string;
	id: number;
	category: string;
	on_air_date_plain: string;
	on_air_time_plain: string;
	genre: string;
	anketa: number;
	country: string;
	cast: string;
	director: string;
	all_news_url: string;
	photos: any;
	hosts: any;
	vacancies: any;
	vacancies_page: any;	
	news: any;
	projects: any;
	playlists: any;
	all_videos_url: string;
	isproject: any;
	first_video: any;
	more_btn_enable: any;
	vcategories: any;
	is_about: any;
	is_scool: any;
	is_out_scool_form: any;
	name_out_scool_form: string;
	slug: string;
	horizontal_ads_1_img: string;
	horizontal_ads_1_url: string;
	horizontal_code_1: string;	
	horizontal_ads_2_img: string;
	horizontal_ads_2_url: string;
	horizontal_code_2: string;	
	horizontal_ads_3_img: string;
	horizontal_ads_3_url: string;
	horizontal_code_3: string;
	profilePos : string;
	telemalysh: any;
}

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.css']
})

export class ProjectComponent implements OnInit{
	
	files: any;
	files2: any;
	
	/**
	* Значения по умолчанию
	*/
	urlLanguage = '/ua';	
	title = '';
	slug = '';
	content = '';
	img = '/assets/img/project_slide.png';
	image_slide = '';
	id = 0;
	category = '';
	on_air_date_plain = '';
	on_air_time_plain = '';
	genre = '';
	country = '';
	cast = '';
	director = '';
	all_news_url = '';
	all_videos_url = '';
	photos = false;
	hosts = false;
	news = false;
	vacancies = false;
	vacancies_page = false;	
	projects = false;
	playlists = false;
	isproject = false;
	first_video = false;
	more_btn_enable = false;
	vcategories = false;
	anketa = 0;
	is_about = false;
	is_scool = false;	
	is_out_scool_form = false;
	name_out_scool_form = '';
	profilePos = '';

	telemalysh = false;
	
	fio_error = false;
	first_name_error = false;
	last_name_error = false;
	surname_error = false;
	gender_error = false;
	about_error = false;
	phone_error = false;
	email_error = false;
	msg_error = false;
	spec_error = false;
	birth_error = false;
	country_error = false;
	city_error = false;
	accept_error = false;
	accept2_error = false;
	portrait_error = false;	
	datebirth_error = false;
	liveplace_error = false;
	position_error = false;
	education_error = false;
	eng_error = false;
	last_job_error = false;
	hobby_error = false;

	
	horizontal_ads_1_img = '';
	horizontal_ads_1_url = '';
	horizontal_code_1 = '';	
	horizontal_ads_2_img = '';
	horizontal_ads_2_url = '';
	horizontal_code_2 = '';	
	horizontal_ads_3_img = '';
	horizontal_ads_3_url = '';
	horizontal_code_3 = '';		
	
	profile_form_success = false;
	profile_form_danger = false;
	profile_form_wait = false;
	profile_form_submit_btn = true;
	public phone_mask = ['+', '3', '8', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	
	url: SafeResourceUrl;	
	
	CarouselOptions = {
		responsiveClass: true,
		items: 4,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 4
			}
		}		
	};
	
	CarouselOptions2 = {
		responsiveClass: true,
		items: 3,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 3
			}
		}		
	};	
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient,
		public sanitizer:DomSanitizer
	) {}
	
	ngOnInit() {	
	
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}				
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}		
		
		if(slug != '' && slug != undefined){	
			var api_url = '//dashboard.inter.ua/api/tv/' + slug + '/' + lang;	
			this.http.get<ProjectInterface>(api_url).subscribe(data => {
				
				this.title = data.title;
				
				if(this.title == '' || this.title == undefined){
					if(lang == 'uk'){
						this.router.navigate(['ua']);
					}else if(lang == 'ru'){
						this.router.navigate(['ru']);
					}if(lang == 'en'){
						this.router.navigate(['en']);
					}
				}
				
				if(data.isproject == true){
					//Если проект, то даем доп поля фронтенду
					this.image_slide = data.image_slide;
					this.category = data.category;
					this.on_air_date_plain = data.on_air_date_plain;
					this.on_air_time_plain = data.on_air_time_plain;
					this.genre = data.genre;
					this.country = data.country;
					this.cast = data.cast;
					this.director = data.director;	
					this.photos = data.photos;
					this.hosts = data.hosts;
					this.news = data.news;
					this.all_news_url = data.all_news_url;
					this.projects = data.projects;
					this.all_videos_url = data.all_videos_url;
					this.img = data.img;
					this.anketa = data.anketa;
					this.more_btn_enable = data.more_btn_enable;
				}else{
					this.first_video = data.first_video;
					this.vcategories = data.vcategories;
					this.vacancies = data.vacancies;
					this.is_scool = data.is_scool;
					this.name_out_scool_form = data.name_out_scool_form;
					this.is_out_scool_form = data.is_out_scool_form;
					this.is_about = data.is_about;
					this.slug = data.slug;
					if(this.first_video){
						this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.first_video[0].video_code);
					}
				}

				if( slug == 'interschool-specialty-telemalysh' || slug == 'interschool-specialty-mediastart' ){
					this.telemalysh = true;
				}

				this.content = data.content;
				this.id = data.id;					
				this.isproject = data.isproject;
				this.playlists = data.playlists;
				this.profile_form_success = false;
				this.profile_form_danger = false;
				this.profile_form_wait = false;
				this.profile_form_submit_btn = true;

				this.horizontal_ads_1_img = data.horizontal_ads_1_img;
				this.horizontal_ads_1_url = data.horizontal_ads_1_url;
				this.horizontal_code_1 = data.horizontal_code_1;	

				this.horizontal_ads_2_img = data.horizontal_ads_2_img;
				this.horizontal_ads_2_url = data.horizontal_ads_2_url;
				this.horizontal_code_2 = data.horizontal_code_2;	

				this.horizontal_ads_3_img = data.horizontal_ads_3_img;
				this.horizontal_ads_3_url = data.horizontal_ads_3_url;
				this.horizontal_code_3 = data.horizontal_code_3;				
				
				this.titleService.setTitle( this.title + ' | Inter' );
			});						
		}else{
			this.router.navigate(['error404']);
		}	
		
	}

	onFormSubmit(profileForm: NgForm) {
		
		if(this.anketa && this.anketa == 1){
			this.profile_form_success = false;
			this.profile_form_danger = false;
			let body = profileForm.value;
			
			let fio, email, phone, msg;
			
			fio = '';
			phone = '';
			email = '';
			msg = '';
			
			if(body.fio != null){
				fio = encodeURIComponent(body.fio);
			}
			if(body.phone != null){
				phone = encodeURIComponent(body.phone);
			}
			if(body.email != null){
				email = encodeURIComponent(body.email);
			}		
			if(body.msg != null){
				msg = encodeURIComponent(body.msg);
			}	

			if(fio == ''){ this.fio_error = true; }else{ this.fio_error = false; }
			if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
			if(email == ''){ this.email_error = true; }else{ this.email_error = false; }
			if(msg == ''){ this.msg_error = true; }else{ this.msg_error = false; }				
			
			if(fio == '' || phone == '' || email == '' || msg == ''){
				this.profile_form_success = false;
				this.profile_form_danger = true;
				return;
			}		
			
			let api_url = '//dashboard.inter.ua/api/profile_form/send?id=' + encodeURIComponent(this.id) + '&fio=' + fio + '&phone=' + phone + '&email=' + email + '&msg=' + msg;
			this.http.get(api_url).subscribe(data => {
				if(data != undefined){
					this.profile_form_success = true;
					this.profile_form_danger = false;
					profileForm.reset();
				}else{
					this.profile_form_success = false;
					this.profile_form_danger = true;				
				}
			});			
		}
		
		if(this.anketa && this.anketa == 2){
			this.profile_form_success = false;
			this.profile_form_danger = false;
			let body = profileForm.value;
			
			let fio, phone, msg, date_birth, liveplace;
			
			fio = '';
			phone = '';
			msg = '';
			date_birth = '';
			liveplace = '';
			
			if(body.fio != null){
				fio = encodeURIComponent(body.fio);
			}
			if(body.phone != null){
				phone = encodeURIComponent(body.phone);
			}	
			if(body.msg != null){
				msg = encodeURIComponent(body.msg);
			}
			if(body.date_birth != null){
				date_birth = encodeURIComponent(body.date_birth);
			}	
			if(body.liveplace != null){
				liveplace = encodeURIComponent(body.liveplace);
			}		

			if(fio == ''){ this.fio_error = true; }else{ this.fio_error = false; }
			if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
			if(msg == ''){ this.msg_error = true; }else{ this.msg_error = false; }	
			if(date_birth == ''){ this.datebirth_error = true; }else{ this.datebirth_error = false; }	
			if(liveplace == ''){ this.liveplace_error = true; }else{ this.liveplace_error = false; }			
			
			if(fio == '' || phone == '' || msg == '' || date_birth == '' || liveplace == ''){
				this.profile_form_success = false;
				this.profile_form_danger = true;
				return;
			}		
			
			let api_url = '//dashboard.inter.ua/api/profile_form/send?id=' + encodeURIComponent(this.id) + '&fio=' + fio + '&phone=' + phone + '&msg=' + msg + '&date_birth=' + date_birth + '&liveplace=' + liveplace;
			this.http.get(api_url).subscribe(data => {
				if(data != undefined){
					this.profile_form_success = true;
					this.profile_form_danger = false;
					profileForm.reset();
				}else{
					this.profile_form_success = false;
					this.profile_form_danger = true;				
				}
			});			
		}		
		
	}
	
	onFormSubmitScool(castingISForm: NgForm) {
		
		this.profile_form_success = false;
		this.profile_form_danger = false;
		this.profile_form_wait = true;
		this.profile_form_submit_btn = false;

		let body = castingISForm.value;
		let send_body = new FormData();
		
		let first_name, last_name, surname, about, phone, email, spec, birth, country, city, accept, accept2, portrait, name_parents;
		
		first_name = '';
		last_name = '';
		surname = '';
		about = '';
		name_parents = '';
		phone = '';
		email = '';
		spec = '';
		birth = '';
		country = '';
		city = '';
		accept = true;
		accept2 = false;
		portrait = '';
		
		if(body.first_name != null){
			first_name = encodeURIComponent(body.first_name);
		}
		
		if(body.last_name != null){
			last_name = encodeURIComponent(body.last_name);
		}
		
		if(body.surname != null){
			surname = encodeURIComponent(body.surname);
		}
		
		if(body.about != null){
			about = encodeURIComponent(body.about);
		}		
		
		if(body.phone != null){
			phone = encodeURIComponent(body.phone);
		}
		if(body.email != null){
			email = encodeURIComponent(body.email);
		}
		if(body.birth != null){
			birth = encodeURIComponent(body.birth);
		}
		if(body.country != null){
			country = encodeURIComponent(body.country);
		}
		if(body.city != null){
			city = encodeURIComponent(body.city);
		}
		if(body.name_parents != null){
			name_parents = encodeURIComponent(body.name_parents);
		}

		if(body.accept != null){
			accept = body.accept;
		}else{
			accept = false;
		}
		if(body.accept2 != null){
			accept2 = body.accept2;
		}else{
			accept2 = false;
		}		
		
		if(first_name == ''){ this.first_name_error = true; }else{ this.first_name_error = false; }
		if(last_name == ''){ this.last_name_error = true; }else{ this.last_name_error = false; }
		if(surname == ''){ this.surname_error = true; }else{ this.surname_error = false; }
		if(about == ''){ this.about_error = true; }else{ this.about_error = false; }
		if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
		if(email == ''){ this.email_error = true; }else{ this.email_error = false; }
		if(birth == ''){ this.birth_error = true; }else{ this.birth_error = false; }
		
		//if(country == ''){ this.country_error = true; }else{ this.country_error = false; }
		
		if(city == ''){ this.city_error = true; }else{ this.city_error = false; }
		if(accept2 == ''){ this.accept2_error = true; }else{ this.accept2_error = false; }

		if(first_name == '' || last_name == '' || surname == '' || about == '' || phone == '' || email == '' || birth == '' || city == '' || accept2 == false){
			this.profile_form_success = false;
			this.profile_form_danger = true;
			this.profile_form_wait = false;
			this.profile_form_submit_btn = true;
			return;
		}
		
		send_body.append('first_name', first_name);
		send_body.append('last_name', last_name);
		send_body.append('surname', surname);
		send_body.append('about', about);
		send_body.append('phone', phone);
		send_body.append('name_parents', name_parents);
		send_body.append('email', email);
		send_body.append('spec', this.title);
		send_body.append('birth', birth);
		send_body.append('country', country);
		send_body.append('city', city);
		send_body.append('accept', accept);
		
		if(this.files && this.files.length > 0){
			for (let i = 0; i < this.files.length; i++) {
				send_body.append('portrait', this.files[i]);
			}		
		}
		
		let api_url = '//dashboard.inter.ua/api/anketa_is_form';
		this.http.post(api_url, send_body).subscribe(data => {
            if(data != undefined){
				this.profile_form_success = true;
				this.profile_form_danger = false;
				castingISForm.reset();
			}else{
				this.profile_form_success = false;
				this.profile_form_danger = true;				
			}
			this.profile_form_wait = false;
			this.profile_form_submit_btn = true;
        });	
		
	}		
	
	onFileChange(event) {
		let target = event.target || event.srcElement;
		this.files = target.files;
	}	
	
	onFormSubmitCasting(castingISForm: NgForm) {
		
		this.profile_form_success = false;
		this.profile_form_danger = false;
		let body = castingISForm.value;
		let send_body = new FormData();
		
		let education, first_name, last_name, surname, gender, birth, country, city, email, phone, position, exp, eng, last_job, hobby, portrait, resume;
		
		first_name = '';
		last_name = '';
		surname = '';
		gender = '';
		birth = '';
		country = '';
		city = '';
		email = '';
		phone = '';
		position = '';
		exp = true;
		eng = '';
		last_job = '';
		hobby = '';
		portrait = '';
		resume = '';
		education = '';	
		
		if(body.first_name != null){
			first_name = encodeURIComponent(body.first_name);
		}
		
		if(body.last_name != null){
			last_name = encodeURIComponent(body.last_name);
		}
		
		if(body.surname != null){
			surname = encodeURIComponent(body.surname);
		}
		
		if(body.gender != null){
			gender = encodeURIComponent(body.gender);
		}		
		
		if(body.birth != null){
			birth = encodeURIComponent(body.birth);
		}		
		
		if(body.country != null){
			country = encodeURIComponent(body.country);
		}		
		
		if(body.city != null){
			city = encodeURIComponent(body.city);
		}	
		
		if(body.email != null){
			email = encodeURIComponent(body.email);
		}			
			
		if(body.phone != null){
			phone = encodeURIComponent(body.phone);
		}
		
			
		if(body.position != null){
			position = encodeURIComponent(body.position);
		}		
		
		if(body.exp != null){
			exp = body.exp;
		}else{
			exp = false;
		}
		
		if(body.eng != null){
			eng = encodeURIComponent(body.eng);
		}	

		if(body.last_job != null){
			last_job = encodeURIComponent(body.last_job);
		}	

		if(body.hobby != null){
			hobby = encodeURIComponent(body.hobby);
		}	

		if(body.portrait != null){
			portrait = encodeURIComponent(body.portrait);
		}	

		if(body.resume != null){
			resume = encodeURIComponent(body.resume);
		}	

		if(body.education != null){
			education = encodeURIComponent(body.education);
		}			
		
		if(first_name == ''){ this.first_name_error = true; }else{ this.first_name_error = false; }
		if(last_name == ''){ this.last_name_error = true; }else{ this.last_name_error = false; }
		if(surname == ''){ this.surname_error = true; }else{ this.surname_error = false; }
		if(gender == ''){ this.gender_error = true; }else{ this.gender_error = false; }
		if(birth == ''){ this.birth_error = true; }else{ this.birth_error = false; }
		if(country == ''){ this.country_error = true; }else{ this.country_error = false; }	
		if(city == ''){ this.city_error = true; }else{ this.city_error = false; }
		if(phone == ''){ this.phone_error = true; }else{ this.phone_error = false; }
		if(email == ''){ this.email_error = true; }else{ this.email_error = false; }
		if(position == ''){ this.position_error = true; }else{ this.position_error = false; }
		if(education == ''){ this.education_error = true; }else{ this.education_error = false; }
		if(eng == ''){ this.eng_error = true; }else{ this.eng_error = false; }
		if(last_job == ''){ this.last_job_error = true; }else{ this.last_job_error = false; }
		if(hobby == ''){ this.hobby_error = true; }else{ this.hobby_error = false; }		

		if(first_name == '' || last_name == '' || surname == '' || gender == '' || birth == '' || country == '' || city == '' || phone == '' || email == '' || position == false || eng == false || last_job == false || hobby == false){
			this.profile_form_success = false;
			this.profile_form_danger = true;
			return;
		}
		
		send_body.append('first_name', first_name);
		send_body.append('education', education);
		send_body.append('last_name', last_name);
		send_body.append('surname', surname);
		send_body.append('gender', gender);
		send_body.append('birth', birth);
		send_body.append('country', country);
		send_body.append('city', city);
		send_body.append('email', email);
		send_body.append('phone', phone);
		send_body.append('position', position);
		send_body.append('exp', exp);
		send_body.append('eng', eng);
		send_body.append('last_job', last_job);
		send_body.append('hobby', hobby);
		
		if(this.files && this.files.length > 0){
			for (let i = 0; i < this.files.length; i++) {
				console.log(this.files[i]);
				send_body.append('portrait', this.files[i]);
			}		
		}
		
		if(this.files2 && this.files2.length > 0){
			for (let i = 0; i < this.files2.length; i++) {
				console.log(this.files2[i]);
				send_body.append('resume', this.files2[i]);
			}		
		}		
		
		let api_url = '//dashboard.inter.ua/api/new_vacancyapp';
		this.http.post(api_url, send_body).subscribe(data => {
            if(data != undefined){
				this.profile_form_success = true;
				this.profile_form_danger = false;
				castingISForm.reset();
			}else{
				this.profile_form_success = false;
				this.profile_form_danger = true;				
			}
        });	
		
	}		

	onFileChange2(event) {
		let target = event.target || event.srcElement;
		this.files2 = target.files;
	}	
	
	show_vacancie_form(title){
		
		this.vacancies_page = true;
		this.profilePos = title;
		setTimeout(function(){
			var vacancie_for = document.getElementById("collapseVacancyApp");
			vacancie_for.scrollIntoView( { behavior: "smooth" } );
		},300);
		
	}

}
