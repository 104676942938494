import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HostListener, Inject } from "@angular/core";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { WINDOW } from '@ng-toolkit/universal';

interface HomeInterface {
	slider: any;
	title: string;
	slide_timeout: number;
	sidebar_ads_1_url: string;
	sidebar_ads_1_img: string;
	sidebar_code_1: string;
	horizontal_ads_1_url: string;
	horizontal_ads_1_img: string;
	horizontal_code_1: string;	
	horizontal_ads_2_url: string;
	horizontal_ads_2_img: string;
	horizontal_code_2: string;	
	horizontal_ads_3_url: string;
	horizontal_ads_3_img: string;	
	horizontal_code_3: string;	
	country: string;
	home_news: any;
	video_items: any;
	tv: any;
	special: any;
	anons: any;
	shedule: any;
}

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	urlLanguage = '/ua';
	slider = false;
	title = 'Inter';
	sidebar_ads_1_url = '';
	sidebar_ads_1_img = '';
	sidebar_code_1 = '';
	horizontal_ads_1_url = '';
	horizontal_ads_1_img = '';
	horizontal_code_1 = '';
	slide_timeout = 5000;
	horizontal_ads_2_url = '';
	country = '';
	horizontal_ads_2_img = '';
	horizontal_code_2 = '';
	horizontal_ads_3_url = '';
	horizontal_ads_3_img = '';
	horizontal_code_3 = '';
	home_news = false;
	video_items = false;
	tv = false;
	special = false;	
	anons = false;
	shedule = false;
	size = 0;
	
	CarouselOptionsHome = {
		responsiveClass: true,
		items: 1,
		nav: true,
		dots: false,
		loop: true,
		margin: 0,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true		
	};		

	CarouselOptions2 = {
		responsiveClass: true,
		items: 3,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 3
			}
		}		
	};		
	
	constructor(@Inject(WINDOW) private window: Window, 
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient
	) { }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
		this.size = this.window.innerWidth;
    }	

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		
		var api_url = '//dashboard.inter.ua/api/home/' + lang;	
		this.http.get<HomeInterface>(api_url).subscribe(data => {
			this.title = data.title;
			this.slider = data.slider;
			this.slide_timeout = data.slide_timeout;
			this.sidebar_ads_1_url = data.sidebar_ads_1_url;
			this.sidebar_ads_1_img = data.sidebar_ads_1_img;
			this.sidebar_code_1 = data.sidebar_code_1;
			this.horizontal_ads_1_url = data.horizontal_ads_1_url;
			this.horizontal_ads_1_img = data.horizontal_ads_1_img;
			this.horizontal_code_1 = data.horizontal_code_1;
			this.horizontal_ads_2_url = data.horizontal_ads_2_url;
			this.horizontal_ads_2_img = data.horizontal_ads_2_img;

			if( window.innerWidth <= 992){
				this.horizontal_ads_2_img = 'https://dashboard.inter.ua/storage/thumbnails/inter_media/2022/12/27/12.jpg';
			}
			
			this.horizontal_code_2 = data.horizontal_code_2;
			this.horizontal_ads_3_url = data.horizontal_ads_3_url;
			this.horizontal_ads_3_img = data.horizontal_ads_3_img;
			this.horizontal_code_3 = data.horizontal_code_3;
			this.home_news = data.home_news;
			this.video_items = data.video_items;
			this.tv = data.tv;
			this.special = data.special;
			this.shedule = data.shedule;
			this.anons = data.anons;
			this.country = data.country;
			this.size = this.window.innerWidth;
			this.titleService.setTitle( this.title );
			
			if(this.slide_timeout > 0){
				this.CarouselOptionsHome = {
					responsiveClass: true,
					items: 1,
					nav: true,
					dots: false,
					loop: true,
					margin: 0,
					autoplay: true,
					autoplayTimeout: this.slide_timeout,
					autoplayHoverPause: true		
				};				
			}else{
				this.CarouselOptionsHome = {
					responsiveClass: true,
					items: 1,
					nav: true,
					dots: false,
					loop: true,
					margin: 0,
					autoplay: false,
					autoplayTimeout: 0,
					autoplayHoverPause: true
				};						
			}
			
		});			
		
	}

}
