<aside>
		
	<div *ngIf="sidebar_ads_1_img; else adsPremium1Template">
		<a href="{{ sidebar_ads_1_url }}">
			<img src="{{ sidebar_ads_1_img }}" class="w-100">
		</a>
		<div class="clear_40"></div>
	</div>
	<ng-template #adsPremium1Template>
		<div class="clear_20 d-lg-none"></div>
		<div id="div-gpt-ad-1516984738506-3"></div>
		<div class="clear_40"></div>
	</ng-template>
	
	<div *ngIf="news_items">
		<h2 class="sidebar-title" translate>Latest news</h2>
		<div class="sidebar-item" *ngFor="let news_item of news_items">
			<div *ngIf="news_item.img">
				<a href="{{news_item.url}}" class="animated-image">
					<img src="{{news_item.img}}" class="w-100">
				</a>
			</div>
			<a href="{{news_item.url}}" class="section-widget-title" [innerHTML]="news_item.title"></a>
			<p class="section-widget-meta">{{news_item.date}}</p>
			<div class="clear_15 d-lg-none"></div>
		</div>		
		<div class="text-center">
			<a class="tag-item" href="{{ urlLanguage }}/news" translate>All news</a>
		</div>							
		<div class="clear_40"></div>
	</div>
						
	<div *ngIf="sidebar_ads_2_img; else adsPremium2Template">
		<a href="{{ sidebar_ads_2_url }}">
			<img src="{{ sidebar_ads_2_img }}" class="w-100">
		</a>
		<div class="clear_40"></div>
	</div>
	<ng-template #adsPremium2Template>
		<div class="clear_20 d-lg-none"></div>
		<div id="div-gpt-ad-1516984738506-4"></div>
		<div class="clear_40"></div>
	</ng-template>	
	
	<div *ngIf="video_items">
		<h2 class="sidebar-title" translate>Popular videos</h2>
		<div class="sidebar-item" *ngFor="let video_item of video_items">
			<div *ngIf="video_item.img">
				<a href="{{video_item.url}}" class="animated-image">
					<img src="{{video_item.img}}" class="w-100">
				</a>
			</div>
			<a href="{{video_item.url}}" class="section-widget-title" [innerHTML]="video_item.title"></a>
			<p class="section-widget-meta">{{video_item.date}}</p>
			<p class="section-widget-meta" *ngIf="video_item.pl_name"><span translate>Channel</span>: <a href="{{video_item.pl_url}}">{{video_item.pl_name}}</a></p>
			<div class="clear_15 d-lg-none"></div>
		</div>			
		<div class="text-center">
			<a class="tag-item" href="{{ urlLanguage }}/video" translate>All videos</a>
		</div>				
		<div class="clear_40"></div>	
	</div>
						
	<div *ngIf="sidebar_ads_3_img">
		<a href="{{ sidebar_ads_3_url }}">
			<img src="{{ sidebar_ads_3_img }}" class="w-100">
		</a>
		<div class="clear_20"></div>
	</div>	
					
</aside>