import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel'; 

import { TextMaskModule } from 'angular2-text-mask';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProjectComponent } from './project/project.component';
import { NewsComponent } from './news/news.component';
import { VideoComponent } from './video/video.component';
import { ProjectsComponent } from './projects/projects.component';
import { AllnewsComponent } from './allnews/allnews.component';
import { LiveComponent } from './live/live.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { SpecprojectsComponent } from './specprojects/specprojects.component';
import { TvComponent } from './tv/tv.component';
import { RatingComponent } from './rating/rating.component';
import { CoordinatesComponent } from './coordinates/coordinates.component';
import { SputnikComponent } from './sputnik/sputnik.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NoComponent } from './no/no.component';
import { CategoryComponent } from './category/category.component';
import { FormsModule } from '@angular/forms';
import { CastingInterscoolComponent } from './casting-interscool/casting-interscool.component';
import { CastingsComponent } from './castings/castings.component';
import { SafePipe } from './safe.pipe';
import { BannerComponent } from './banner/banner.component';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';

import { AllForYouCastingComponent } from './all-for-you-casting/all-for-you-casting.component';
import { MatomoModule } from 'ngx-matomo';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectComponent,
    NewsComponent,
    VideoComponent,
    ProjectsComponent,
    AllnewsComponent,
    LiveComponent,
    PlaylistComponent,
    SpecprojectsComponent,
    TvComponent,
    RatingComponent,
    CoordinatesComponent,
    SputnikComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    NoComponent,
    CategoryComponent,
    CastingInterscoolComponent,
    CastingsComponent,
    SafePipe,
	  BannerComponent,
    AllForYouCastingComponent,
  ],
  imports: [
	  TextMaskModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
	  FormsModule,
	  HttpClientModule,
    AppRoutingModule,
	  OwlModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    MatomoModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}