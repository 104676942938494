<app-header></app-header>

<section class="article-page">
	<div class="container py-5">
		
		<h2 class="article-title" translate>Contacts</h2>
		
		<div class="videoteka-tags">
			<a class="tag-item" href="{{ urlLanguage }}/about" translate>About</a> 
			<!--
			<a class="tag-item" href="{{ urlLanguage }}/about/rating" translate>Rating</a> 
			-->
			<a class="tag-item" target="_blank" href="{{ urlLanguage }}/news/category/awards" translate>Awards</a> 
			<a class="tag-item" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a>
			<a class="tag-item active" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a> 
			<a class="tag-item" href="{{ urlLanguage }}/sputnik" translate>Satellite</a> 
			<a class="tag-item" href="{{ urlLanguage }}/castings" translate>Castings</a> 

			<a class="tag-item" href="http://інтер.укр" target="_blank" rel="nofollow" translate>Ownership structure</a>
			
		</div>
				
		<div class="clear_15"></div>		
		
		<div class="bg-white padding-36">
		
			<div class="row">
				<div class="col-12 col-md-6">
					<img src="assets/img/inter_map.png" class="w-100">
					<div class="clear_15"></div>
				</div>
				<div class="col-12 col-md-6">
					
					<article>
					
						<h1 translate>Contacts</h1>

						<div class="clear_15"></div>
						
						<h3><span translate>PJSC</span> "<span translate>Tv channel</span> "<span translate>Inter</span>"</h3>
						<div class="clear_15"></div>
						<p translate>st. Dmitrievskaya, 30, Kiev 01054, Ukraine</p>
						
						<h3 translate>Phone/Fax</h3>
						<div class="clear_15"></div>
						<p>+38 (044) 490 67 65</p>	
						
						<h3 translate>You can contact us</h3>
						<div class="clear_15"></div>
						
						<div class="row">
							<div class="col-12 col-md-6" translate>Common department</div>
							<div class="col-12 col-md-6">inter@inter.ua</div>
						</div>
						
						<div class="clear_15"></div>
						
						<div class="row">
							<div class="col-12 col-md-6" translate>Public relations service</div>
							<div class="col-12 col-md-6">pr@inter.ua</div>
						</div>
						<div class="clear_15"></div>
						<div class="row">
							<div class="col-12 col-md-6" translate>Registry Media ID</div>
							<div class="col-12 col-md-6">L10-00290, R10-00294</div>
						</div>

						<div class="clear_15"></div>
						<div class="row">
							<div class="col-12 col-md-6" translate>TV channel INTER+</div>
							<div class="col-12 col-md-6">R10-00443</div>
						</div>

						<div class="clear_15"></div>
						<div class="row">
							<div class="col-12 col-md-6" translate>Media identifier in the Register of the "INTER UKRAINE" TV channel:</div>
							<div class="col-12 col-md-6">L10-01929</div>
						</div>
						
						<div class="clear_15"></div>
						
					</article>
					
				</div>							
			</div>	

			<div class="clear_40"></div>
			
			<div class="row">
			
				<div class="col-12 col-md-8">
					
					<article>
						
						<h2 translate>For advertising</h2>
						<div class="clear_15"></div>
						
						<div class="row">
							
							<div class="col-12 col-md-6">
							
								<p><strong><span translate>direct advertising</span>:</strong></p>
								<img src="assets/img/contact_logo_1.png" class="w-50">
								<div class="clear_30"></div>

								<p><span translate>Phone</span>: +380 44 486 99 23</p>

								<p><span translate>Fax</span>: 0 (044) 284-04-98</p>

								<p>E-mail: ad@inter-reklama.com.ua</p>
								 
								<p>www.inter-reklama.com.ua</p>

								<p><strong translate>advertising on the site (or on the Internet)</strong></p>
								
								<p><span translate>Ltd</span> "<span translate>Great advertisement</span>"</p>
								<!--
								<p translate>inter_all_cities</p>

								<p translate>K1, NTN, Pixel - regional tie-ins in the city of Kiev.</p>
								-->

								<p><span translate>Phone</span>: +380 044 490 81 81</p>

								<p>E-mail: nteller@vidminna.com.ua</p>
								
								<div class="clear_15"></div>
								<h3 translate>Interactive projects on the air</h3>
								<div class="clear_15"></div>

								<p><strong>"<span translate>VIA Teleport</span>"</strong></p>

								<p><span translate>Phone</span>: 0 (044) 284-04-97; 0 (044) 284-04-99</p>

								<p><span translate>Fax</span>: 0 (044) 284-04-98</p>

								<p>E-mail: info@intertext.com.ua</p>								
								
							</div>
							
							<div class="col-12 col-md-6">
							
								<p><strong><span translate>sponsorship placement</span>:</strong></p>
								<img src="assets/img/contact_logo_2.png" class="w-50">
								<div class="clear_30"></div>
								
								<p><span translate>Ltd</span> "<span translate>Highline Media</span>"</p>

								<p><span translate>Phone</span>: +380 44 490 67 94</p>

								<p>E-mail: intercom@inter.ua</p>

								<p>www.highlinemedia.com.ua</p>

								<p><strong translate>advertising on the site (or on the Internet)</strong></p>
								
								<p><span translate>Phone</span>: +380 044 486 82 96</p>	

								<p>E-mail: digital@inter-reklama.com.ua</p>	

								<p>www.inter-reklama.com.ua</p>	
								
								<div class="clear_15"></div>
								<h3><span translate>TV channel coordinates</span> "<span translate>Inter+</span>"</h3>
								<div class="clear_15"></div>
								
								<a href="http://interplus.ua/ru/about/coordinates" target="_blank">http://interplus.ua/ru/about/coordinates</a>
								
							</div>							
							
						</div>
						
					</article>
					
				</div>
				
				<div class="col-12 col-md-4">				
					<app-sidebar></app-sidebar>					
				</div>			
			</div>
		
		</div>
		
	</div>
</section>

<app-footer></app-footer>