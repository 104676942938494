<app-header></app-header>

<section>
	<div class="container py-5">	
		
		<h1 class="article-title" translate>Castings</h1>
		
		<div class="videoteka-tags">
			<a class="tag-item" href="{{ urlLanguage }}/about" translate>About</a> 
			<!--
			<a class="tag-item" href="{{ urlLanguage }}/about/rating" translate>Rating</a> 
			-->
			<a class="tag-item" target="_blank" href="{{ urlLanguage }}/news/category/awards" translate>Awards</a> 
			<a class="tag-item" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a>
			<a class="tag-item" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a> 
			<a class="tag-item" href="{{ urlLanguage }}/sputnik" translate>Satellite</a> 
			<a class="tag-item active" href="{{ urlLanguage }}/castings" translate>Castings</a> 
			
			<a class="tag-item" href="http://інтер.укр" target="_blank" rel="nofollow" translate>Ownership structure</a>
			
		</div>		
		
		<div class="clear_15"></div>
		<div class="row">
			<div class="col-12 col-md-8">				
		
				<article>
					<p translate>casting_common_1</p>
					<p translate>casting_common_2</p>
					<p translate>casting_common_3</p>
					<form class="form-anketa" method="POST" enctype="multipart/form-data" #castingISForm="ngForm" (ngSubmit)="onFormSubmit(castingISForm)">
						<div class="form-group">
							<label translate>Name</label>
							<input type="text" name="fio" class="form-control" ngModel required #profileFio="ngModel">
							<ng-container *ngIf="fio_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>	
						<div class="form-group">
							<label translate>Gender</label>
							<select class="form-control" name="gender" ngModel required #profileGender="ngModel">
								<option translate>Male</option>
								<option translate>Female</option>
							</select>	
							<ng-container *ngIf="gender_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>							
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<label translate>Choose the positions you are applying for</label>
							<select class="form-control" name="positionambit" ngModel required #profilePositionambit="ngModel">
								<option translate>Studio viewer</option>
								<option translate>Project participant (hero)</option>
								<option translate>Actor of mass scenes</option>
								<option translate>Leading</option>
								<option translate>Speaker</option>
								<option translate>Professional Actor</option>
								<option translate>Actor Amator</option>
								<option translate>Expert</option>
							</select>								
							<ng-container *ngIf="positionambit_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>							
							<div class="clear_5"></div>
						</div>						
						<div class="form-group">
							<label translate>Date of Birth</label>
							<input type="text" name="birth" class="form-control" ngModel required #profileBirth="ngModel">
							<ng-container *ngIf="birth_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>							
							<div class="clear_5"></div>
						</div>				
						<div class="form-group">
							<label translate>Country</label>
							<input type="text" name="country" class="form-control" ngModel required #profileCountry="ngModel">
							<ng-container *ngIf="country_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>					
						<div class="form-group">
							<label translate>City</label>
							<input type="text" name="city" class="form-control" ngModel required #profileCity="ngModel">
							<ng-container *ngIf="city_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>							
						<div class="form-group">
							<label translate>Phone</label>
							<input type="text" name="phone" class="form-control" ngModel required #profilePhone="ngModel">
							<ng-container *ngIf="phone_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>	
						<div class="form-group">
							<label>E-mail</label>
							<input type="email" name="email" class="form-control" ngModel required #profileEmail="ngModel">
							<ng-container *ngIf="email_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
						</div>	
						<div class="form-group">
							<label translate>Your photo (portrait)</label> (.jpg, .jpeg, .png)<br>
							<input type="file" accept=".jpg, .jpeg, .png" class="form-control-file" id="portrait" name="portrait" ngModel required #profilePortrait="ngModel" (change)="onFileChange($event)">
							<ng-container *ngIf="portrait_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
							<div class="clear_5"></div>
							<div class="clear_5"></div>
						</div>						
						<div class="form-group">
							<div class="form-check">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" name="accept" value="1" ngModel required #profileAccept="ngModel"><span translate>I agree to receive notifications to my e-mail about new castings</span>
								</label>
							</div>	
							<ng-container *ngIf="accept_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>								
							<div class="clear_5"></div>
							<div class="clear_5"></div>
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" name="accept2" value="1" ngModel required #profileAccept2="ngModel"><span translate>I agree to the processing of my personal data and the publication of my data on the site</span>
								</label>
							</div>							
						</div>		
						<ng-container *ngIf="accept2_error">
							<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
						</ng-container>
						<div class="clear_5"></div>
						<div *ngIf="profile_form_wait" class="alert alert-wait" role="alert" translate>Please wait while your application is sent to managers</div>
						<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>Your profile has been sent successfully!</div>
						<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
						<div class="text-right">
							<button *ngIf="profile_form_submit_btn" type="submit" class="tag-item big active" translate>Send Application</button>
						</div>
					</form>	
				</article>
						
				<div class="clear_40"></div>		
				
			</div>
			<div class="col-12 col-md-4">
				<app-sidebar></app-sidebar>	
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>