import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';

interface SheduleInterface {
	day_1_items: any;
	day_2_items: any;
	day_3_items: any;
	day_4_items: any;
	day_5_items: any;
	day_6_items: any;
	day_7_items: any;
	day_1_date: string;
	day_2_date: string;
	day_3_date: string;
	day_4_date: string;
	day_5_date: string;
	day_6_date: string;
	day_7_date: string;	
	day_1_out: boolean;
	day_2_out: boolean;
	day_3_out: boolean;
	day_4_out: boolean;
	day_5_out: boolean;
	day_6_out: boolean;
	day_7_out: boolean;
	current_day: number;
	current_time: string;
}

@Component({
	selector: 'app-tv',
	templateUrl: './tv.component.html',
	styleUrls: ['./tv.component.css']
})
export class TvComponent implements OnInit {
	
	day_1_items = false;	
	day_2_items = false;
	day_3_items = false;
	day_4_items = false;
	day_5_items = false;
	day_6_items = false;
	day_7_items = false;
	day_1_date = '';
	day_2_date = '';
	day_3_date = '';
	day_4_date = '';
	day_5_date = '';
	day_6_date = '';
	day_7_date = '';	
	urlLanguage = '/ua';
	current_day = 1;
	current_time = '';
	
	day_1_out = true;
	day_2_out = true;
	day_3_out = true;
	day_4_out = true;
	day_5_out = true;
	day_6_out = true;
	day_7_out = true;

	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient
	) { }
	
	click_mon(){
		this.day_1_out = true;
		this.day_2_out = true;
		this.day_3_out = true;
		this.day_4_out = true;
		this.day_5_out = true;
		this.day_6_out = true;
		this.day_7_out = true;	
		this.current_day = 1;
	}
	
	click_tue(){
		this.day_1_out = false;
		this.day_2_out = true;
		this.day_3_out = true;
		this.day_4_out = true;
		this.day_5_out = true;
		this.day_6_out = true;
		this.day_7_out = true;
		this.current_day = 2;		
	}

	click_wed(){
		this.day_1_out = false;
		this.day_2_out = false;
		this.day_3_out = true;
		this.day_4_out = true;
		this.day_5_out = true;
		this.day_6_out = true;
		this.day_7_out = true;		
		this.current_day = 3;
	}

	click_thu(){
		this.day_1_out = false;
		this.day_2_out = false;
		this.day_3_out = false;
		this.day_4_out = true;
		this.day_5_out = true;
		this.day_6_out = true;
		this.day_7_out = true;	
		this.current_day = 4;
	}

	click_fri(){
		this.day_1_out = false;
		this.day_2_out = false;
		this.day_3_out = false;
		this.day_4_out = false;
		this.day_5_out = true;
		this.day_6_out = true;
		this.day_7_out = true;	
		this.current_day = 5;
	}

	click_sat(){
		this.day_1_out = false;
		this.day_2_out = false;
		this.day_3_out = false;
		this.day_4_out = false;
		this.day_5_out = false;
		this.day_6_out = true;
		this.day_7_out = true;	
		this.current_day = 6;
	}

	click_sun(){
		this.day_1_out = false;
		this.day_2_out = false;
		this.day_3_out = false;
		this.day_4_out = false;
		this.day_5_out = false;
		this.day_6_out = false;
		this.day_7_out = true;	
		this.current_day = 7;
	}	

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		var api_url = '//dashboard.inter.ua/api/shedule/' + lang;	
		this.http.get<SheduleInterface>(api_url).subscribe(data => {
			
			this.day_1_items = data.day_1_items;
			this.day_2_items = data.day_2_items;
			this.day_3_items = data.day_3_items;
			this.day_4_items = data.day_4_items;
			this.day_5_items = data.day_5_items;
			this.day_6_items = data.day_6_items;
			this.day_7_items = data.day_7_items;
			this.day_1_date = data.day_1_date;
			this.day_2_date = data.day_2_date;
			this.day_3_date = data.day_3_date;
			this.day_4_date = data.day_4_date;
			this.day_5_date = data.day_5_date;
			this.day_6_date = data.day_6_date;
			this.day_7_date = data.day_7_date;	
			this.current_day = data.current_day;
			
			this.day_1_out = data.day_1_out;
			this.day_2_out = data.day_2_out;
			this.day_3_out = data.day_3_out;
			this.day_4_out = data.day_4_out;
			this.day_5_out = data.day_5_out;
			this.day_6_out = data.day_6_out;
			this.day_7_out = data.day_7_out;
			
			this.current_time = data.current_time;
			
			if(lang == 'uk'){
				this.titleService.setTitle( 'Телепрограма | Inter' );
			}else if(lang == 'ru'){
				this.titleService.setTitle( 'Телепрограмма | Inter' );
			}else{
				this.titleService.setTitle( 'TV Program | Inter' );
			}
			
		});		
		
	}

}
