import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';

interface PlaylistInterface {
	title: string;
	items: any;
	is_last: any;
}

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css']
})

export class PlaylistComponent implements OnInit {
	
	urlLanguage = '/ua';
	items = [];
	title = '';	
	offset = 21;	
	is_last: false;
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient
	) { }
	
    load_more(){
		
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}	
		if(slug == '' || slug == undefined){
			slug = 'all';
		}

		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		
		var api_url = '//dashboard.inter.ua/api/playlist/' + slug + '/33/' + this.offset + '/' + lang;
		this.offset+=33;

		this.http.get<PlaylistInterface>(api_url).subscribe(data => {
			if(this.items && data.items){
				for (let entry of data.items) {
					if(entry){
						this.items.push(entry);
					}
				}			
			}
			this.is_last = data.is_last;
		});
		
    }	

	ngOnInit() {
		
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}	
		if(slug == '' || slug == undefined){
			slug = 'all';
		}

		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		
		var api_url = '//dashboard.inter.ua/api/playlist/' + slug + '/45/0/' + lang;
		
		this.http.get<PlaylistInterface>(api_url).subscribe(data => {
			this.items = data.items;
			this.title = data.title;
			
			if(this.title == '' || this.title == undefined){
				if(lang == 'uk'){
					this.router.navigate(['ua']);
				}else if(lang == 'ru'){
					this.router.navigate(['ru']);
				}if(lang == 'en'){
					this.router.navigate(['en']);
				}
			}				
			
			this.is_last = data.is_last;
			this.titleService.setTitle( this.title + ' | Inter' );
		});
		
	}

}
