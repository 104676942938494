import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';

interface SpecProjectsInterface {
	title: string;
	items: any;
	playlists: any;
}

@Component({
	selector: 'app-specprojects',
	templateUrl: './specprojects.component.html',
	styleUrls: ['./specprojects.component.css']
})
export class SpecprojectsComponent implements OnInit {
	
	urlLanguage = '/ua';
	playlists = false;
	items = false;
	title = '';	
	
	CarouselOptions = {
		responsiveClass: true,
		items: 3,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 3
			}
		}		
	};		
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient		
	) { }

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
				
		if(lang == 'ua'){
			lang = 'uk';
		}
		
		var api_url = '//dashboard.inter.ua/api/tvprojects/' + lang + '/special';
		
		this.http.get<SpecProjectsInterface>(api_url).subscribe(data => {
			this.items = data.items;
			this.playlists = data.playlists;
			this.title = data.title;
			this.titleService.setTitle( this.title + ' | Inter' );
		});	
		
	}

}
