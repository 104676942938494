<app-header></app-header>

<section>
	<div class="container py-5">
		
		<!--
		<div class="row">
			<div class="col-12 col-md-6 col-lg-7">
				<h1 class="article-title" translate>Video</h1>
			</div>
			<div class="col-12 col-md-6 col-lg-5 text-left text-md-right">
				<div class="row">
					<div class="col-12 col-sm-6">
						<select class="form-control bg-lightgray">
							<option translate>All</option>
							<option>Утро с Интером</option>
							<option>Художественные фильмы</option>
							<option>Документальные фильмы</option>
							<option>Социальные проекты</option>
							<option>Путешествия</option>
							<option>Сериалы</option>
							<option>Концерты</option>
						</select>					
					</div>
					<div class="col-12 col-sm-6">
						<input type="text" name="keyword" placeholder="{{'Search Video' | translate}}" class="search-inner-video">
					</div>					
				</div>
			</div>			
		</div>
		<div class="clear_15"></div>
		-->
		
		<div class="row">
		
			<div class="col-12 col-md-8">
				
				<div class="horizontal-widget no-border">
					
					<h2 class="horizontal-widget-title">{{title}}</h2>
					
					<div class="row" *ngIf="items">
						<div class="col-12 col-md-4" *ngFor="let row of items">
							<div *ngIf="row.img">
								<a href="{{row.url}}" class="animated-image">
									<img src="{{row.img}}" class="w-100">
								</a>
							</div>
							<ng-container *ngIf="row.category_title != ''"><div class="clear_15"></div><a class="tag-item m-0 active" href="{{row.category_url}}">{{row.category_title}}</a></ng-container>
							<a href="{{row.url}}" class="section-widget-title">{{row.title}}</a>
							<p class="section-widget-meta">{{row.date}}</p>
							<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{row.project_url}}">{{row.project_name}}</a></p>
							<div class="clear_15"></div>
						</div>					
					</div>	
					<div class="text-center" *ngIf="is_last">
						<button class="section-widget-btn" (click)="load_more()" translate>More Videos</button>
					</div>						
					
				</div>
				
			</div>
			
			<div class="col-12 col-md-4">			
				<app-sidebar></app-sidebar>				
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>