import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';

interface AllNewsInterface {
	title: string;
	is_last: any;
	items: any;
	categories: any;
}

@Component({
  selector: 'app-allnews',
  templateUrl: './allnews.component.html',
  styleUrls: ['./allnews.component.css']
})
export class AllnewsComponent implements OnInit  {
	
	urlLanguage = '/ua';
	items = [];
	categories = false;
	title = '';	
	category = '';	
	is_last: false;
	offset = 21;
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient		
	) { }
	
    load_more(){
		
		if (this.route.snapshot.params['project']) {
			var project = this.route.snapshot.params['project'];
		}else{
			var project = this.route.snapshot.data['project'];
		}	
		if(project == '' || project == undefined){
			project = 'all';
		}

		if (this.route.snapshot.params['slug']) {
			var category = this.route.snapshot.params['slug'];
		}else{
			var category = this.route.snapshot.data['slug'];
		}	
		if(category == '' || category == undefined){
			category = 'all';
		}

		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		
		var api_url = '//dashboard.inter.ua/api/stories/' + project + '/' + category + '/21/' + this.offset + '/' + lang;
		this.offset+=21;

		this.http.get<AllNewsInterface>(api_url).subscribe(data => {
			if(this.items && data.items){
				for (let entry of data.items) {
					if(entry){
						this.items.push(entry);
					}
				}			
			}
			this.is_last = data.is_last;
		});
		
    }		
  
	ngOnInit() {  
	
		if (this.route.snapshot.params['project']) {
			var project = this.route.snapshot.params['project'];
		}else{
			var project = this.route.snapshot.data['project'];
		}	
		if(project == '' || project == undefined){
			project = 'all';
		}

		if (this.route.snapshot.params['slug']) {
			var category = this.route.snapshot.params['slug'];
		}else{
			var category = this.route.snapshot.data['slug'];
		}	
		if(category == '' || category == undefined){
			category = 'all';
		}

		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		
		var api_url = '//dashboard.inter.ua/api/stories/' + project + '/' + category + '/21/0/' + lang;
		
		this.http.get<AllNewsInterface>(api_url).subscribe(data => {
			this.items = data.items;
			this.categories = data.categories;
			this.title = data.title;
			this.is_last = data.is_last;
			this.titleService.setTitle( this.title + ' | Inter' );
		});
		
	}

}
