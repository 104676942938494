<header>
	<nav class="navbar navbar-expand-lg navbar-inter">
		<div class="container">
			<a class="navbar-brand" href="{{ urlLanguage }}">
				<img src="assets/img/logo.svg">
			</a>
			<div class="d-lg-none w-50 text-right">
				<!--
				<a  class="mobile-search-button" href="/search"><img src="assets/img/search_button.svg"></a>
				-->
				<button class="navbar-toggler d-inline" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>					
			</div>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav mr-auto">
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/live" translate>Live</a>
					</li>
					
					<ng-template [ngIf]="urlLanguage != '/en'">
						<li class="nav-item">
							<a class="nav-link" href="https://inter.ua/prokydaemosia" translate>Wake up</a>
						</li>
					</ng-template>
					
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/tv" translate>TV Program</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/video" translate>Video</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/programs" translate>TV Projects</a>
					</li>
					<!--
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/news/inter-media-group-odnovremenno-perevela-veshanie-svoih-telekanalov-v-format-hd">HD</a>
					</li>
					-->
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/news" translate>News</a>
					</li>
					
					<li class="nav-item">
						<a class="nav-link" href="https://deti.inter.ua/" translate>Inter for kids</a>
					</li>
					<!--
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/special-projects" translate>Special Projects</a>
					</li>

					
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/about" translate>About</a>
					</li>
					-->
					
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/interschool" translate>Interschool</a>
					</li>	
					
					<li class="nav-item">
						<a class="nav-link" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a>
					</li>
					
				</ul>
				<div class="my-2 my-lg-0">
					<ul class="navbar-nav mr-auto">
						<!--
						<li class="nav-item d-none d-lg-flex">
							<a class="nav-link" href="{{ urlLanguage }}/search"><img src="assets/img/search_button.svg"></a>
						</li>
						-->
						<li class="nav-item d-none d-lg-flex">
							<a target="_blank" rel="nofollow" href="https://www.facebook.com/Inter.ua/" class="footer-social"><img src="assets/img/facebook.svg"></a> 
						</li>
						<li class="nav-item d-none d-lg-flex">
							<a target="_blank" rel="nofollow" href="https://www.youtube.com/user/InterTVua/" class="footer-social"><img src="assets/img/youtube.svg"></a> 
						</li>
						<li class="nav-item d-none d-lg-flex">
							<a target="_blank" rel="nofollow" href="https://www.instagram.com/inter_tv_channel/" class="footer-social"><img src="assets/img/instagram.svg"></a>			
						</li>
						
						<li class="nav-item lang-selector">
							<div class="dropdown show">
							
								<ng-template [ngIf]="urlLanguage == '/ua'">
									<a class="btn btn-secondary dropdown-toggle" href="{{ thisURLua }}" role="button" id="dropdownLangLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										UA
									</a>
									<div class="dropdown-menu" aria-labelledby="dropdownLangLink">
										<a class="dropdown-item active" href="{{ thisURLua }}">UA</a>
										<a class="dropdown-item" href="{{ thisURLru }}">RU</a>
										<a class="dropdown-item" href="{{ thisURLen }}">EN</a>
									</div>
								</ng-template>
								<ng-template [ngIf]="urlLanguage == '/ru'">
									<a class="btn btn-secondary dropdown-toggle" href="{{ thisURLru }}" role="button" id="dropdownLangLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										RU
									</a>
									<div class="dropdown-menu" aria-labelledby="dropdownLangLink">
										<a class="dropdown-item" href="{{ thisURLua }}">UA</a>
										<a class="dropdown-item active" href="{{ thisURLru }}">RU</a>
										<a class="dropdown-item" href="{{ thisURLen }}">EN</a>
									</div>	
								</ng-template>
								<ng-template [ngIf]="urlLanguage == '/en'">
									<a class="btn btn-secondary dropdown-toggle" href="{{ thisURLen }}" role="button" id="dropdownLangLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										EN
									</a>
									<div class="dropdown-menu" aria-labelledby="dropdownLangLink">
										<a class="dropdown-item" href="{{ thisURLua }}">UA</a>
										<a class="dropdown-item" href="{{ thisURLru }}">RU</a>
										<a class="dropdown-item active" href="{{ thisURLen }}">EN</a>
									</div>
								</ng-template>															

							</div>						
						</li>			
						<!--
						<li class="nav-item">
							<a class="nav-link" href="{{ urlLanguage }}/login" translate>Login</a>
						</li>
						-->
					</ul>
				</div>
			</div>
		</div>
	</nav>
</header>