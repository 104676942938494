import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Component({
	selector: 'app-coordinates',
	templateUrl: './coordinates.component.html',
	styleUrls: ['./coordinates.component.css']
})
export class CoordinatesComponent implements OnInit {
	
	urlLanguage = '/ua';
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService		
	) { }

	ngOnInit() {
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
				
		this.titleService.setTitle('Контакты | Inter');
		
	}

}
