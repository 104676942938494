<app-header></app-header>

<section>
	<div class="container py-5">

		<div class="row align-items-center">
			<div class="col-12">
				<h1 class="article-title m-0" translate>TV Program</h1>
			</div>
		</div>
		
		<div class="clear_15"></div>	
		
		<div class="row">
		
			<div class="col-12 col-md-8">
				
				<!--
				<div class="row align-items-center">
					<div class="col-12 col-sm-12 col-md-7 text-center text-md-left">
						<div class="week-selector owl-carousel">
							<div class="item" translate>TV show for a week</div>
							<div class="item" translate>TV show for a week</div>
						</div>
						<div class="clear_15"></div>
					</div>
					<div class="col-12 col-sm-12 col-md-5 text-center text-md-left">
						<div class="tv-days">
							<span>ПН</span>
							<span class="day-active">ВТ</span>
							<span>СР</span>
							<span>ЧТ</span>
							<span>ПТ</span>
							<span>СБ</span>
							<span>ВС</span>
						</div>
						<div class="clear_15"></div>
					</div>			
				</div>				
				-->
				
				<div class="tv-days">
					<span *ngIf="current_day == 1; else day1Template" class="day-active" (click)="click_mon()" translate>Mon</span>
					<ng-template #day1Template>
						<span (click)="click_mon()" translate>Mon</span>
					</ng-template>
					<span *ngIf="current_day == 2; else day2Template" class="day-active" (click)="click_tue()" translate>Tue</span>
					<ng-template #day2Template>
						<span (click)="click_tue()" translate>Tue</span>
					</ng-template>
					<span *ngIf="current_day == 3; else day3Template" class="day-active" (click)="click_wed()" translate>Wed</span>
					<ng-template #day3Template>
						<span (click)="click_wed()" translate>Wed</span>
					</ng-template>
					<span *ngIf="current_day == 4; else day4Template" class="day-active" (click)="click_thu()" translate>Thu</span>
					<ng-template #day4Template>
						<span (click)="click_thu()" translate>Thu</span>
					</ng-template>
					<span *ngIf="current_day == 5; else day5Template" class="day-active" (click)="click_fri()" translate>Fri</span>
					<ng-template #day5Template>
						<span (click)="click_fri()" translate>Fri</span>
					</ng-template>
					<span *ngIf="current_day == 6; else day6Template" class="day-active" (click)="click_sat()" translate>Sat</span>
					<ng-template #day6Template>
						<span (click)="click_sat()" translate>Sat</span>
					</ng-template>
					<span *ngIf="current_day == 7; else day7Template" class="day-active" (click)="click_sun()" translate>Sun</span>
					<ng-template #day7Template>
						<span (click)="click_sun()" translate>Sun</span>
					</ng-template>					
				</div>
				<div class="clear_15"></div>				
				
				<ng-container *ngIf="day_1_out">
					<div *ngIf="day_1_items">
						<div class="tv-program-item">
							<h2>{{ day_1_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_1_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 1 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>
				</ng-container>
				
				<ng-container *ngIf="day_2_out">
					<div *ngIf="day_2_items">
						<div class="tv-program-item">
							<h2>{{ day_2_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_2_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 2 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>
				</ng-container>

				<ng-container *ngIf="day_3_out">
					<div *ngIf="day_3_items">
						<div class="tv-program-item">
							<h2>{{ day_3_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_3_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 3 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>
				</ng-container>

				<ng-container *ngIf="day_4_out">
					<div *ngIf="day_4_items">
						<div class="tv-program-item">
							<h2>{{ day_4_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_4_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 4 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>
				</ng-container>

				<ng-container *ngIf="day_5_out">
					<div *ngIf="day_5_items">
						<div class="tv-program-item">
							<h2>{{ day_5_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_5_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 5 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>
				</ng-container>

				<ng-container *ngIf="day_6_out">
					<div *ngIf="day_6_items">
						<div class="tv-program-item">
							<h2>{{ day_6_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_6_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 6 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>		
				</ng-container>

				<ng-container *ngIf="day_7_out">
					<div *ngIf="day_7_items">
						<div class="tv-program-item">
							<h2>{{ day_7_date }}</h2>
							<div class="two_cols">
								<p *ngFor="let program of day_7_items">
									<span>{{program.time}}</span> {{program.title}} <a *ngIf="current_day == 7 && current_time == program.time;" href="{{ urlLanguage }}/live"><img src="assets/img/mini_live.png"><span translate>Now online</span></a>
								</p>
							</div>
						</div>	
					</div>
				</ng-container>				
				
			</div>
			
			<div class="col-12 col-md-4">			
				<app-sidebar></app-sidebar>				
			</div>			
		</div>
		
		<!--
		<div class="clear_60"></div>

		<section class="section-widget">
			<div class="container">
				<h2>СПЕЦПРОЕКТЫ</h2>
				<div class="special-slider owl-carousel">
					<div class="item">
						<a href="{{ urlLanguage }}/specproject-slug" class="animated-image"><img src="assets/img/spec_1.png" class="w-100"></a>	
						<div class="clear_15"></div>
						<div class="text-center">
							<a href="{{ urlLanguage }}/specproject-slug">«Интер». Один на всех </a>
						</div>
					</div>
					<div class="item">
						<a href="{{ urlLanguage }}/specproject-slug" class="animated-image"><img src="assets/img/spec_2.png" class="w-100"></a>
						<div class="clear_15"></div>				
						<div class="text-center">
							<a href="{{ urlLanguage }}/specproject-slug">«Интер». Один на всех </a>
						</div>				
					</div>
					<div class="item">
						<a href="{{ urlLanguage }}/specproject-slug" class="animated-image"><img src="assets/img/spec_3.png" class="w-100"></a>	
						<div class="clear_15"></div>
						<div class="text-center">
							<a href="{{ urlLanguage }}/specproject-slug">«Интер». Один на всех </a>
						</div>				
					</div>
					<div class="item">
						<a href="{{ urlLanguage }}/specproject-slug" class="animated-image"><img src="assets/img/spec_2.png" class="w-100"></a>	
						<div class="clear_15"></div>
						<div class="text-center">
							<a href="{{ urlLanguage }}/specproject-slug">«Интер». Один на всех </a>
						</div>				
					</div>			
				</div>		
			</div>
		</section>		
		-->
		
	</div>
</section>

<app-footer></app-footer>