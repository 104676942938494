import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

interface StoryInterface {
	title: string;
	body: any;
	img: any;
	date: string;
	videoCode: any;
	horizontal_ads_2_img: string;
	horizontal_ads_2_url: string;
	redirect: string;
	horizontal_code_2: string;	
}

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
	
	urlLanguage = '/ua';
	title = '';
	body = null;
	img = '';	
	date = '';	
	horizontal_ads_2_img = '';
	horizontal_ads_2_url = '';
	redirect = '';
	horizontal_code_2 = '';	
	videoCode = null;
	url: SafeResourceUrl;
	
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient,
		public sanitizer:DomSanitizer
	) { }

	ngOnInit() {
		
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}			
		
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}
		
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}
		
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
		var api_url = '//dashboard.inter.ua/api/story/' + slug + '/' + lang;	
		this.http.get<StoryInterface>(api_url).subscribe(data => {
			
			if(data.redirect != ''){
				document.location.href = data.redirect;
			}
			
			this.title = data.title;
			
			if(this.title == '' || this.title == undefined){
				if(lang == 'uk'){
					this.router.navigate(['ua']);
				}else if(lang == 'ru'){
					this.router.navigate(['ru']);
				}if(lang == 'en'){
					this.router.navigate(['en']);
				}
			}				
			
			this.body = this.sanitizer.bypassSecurityTrustHtml(data.body);
			this.img = data.img;
			this.date = data.date;
			this.horizontal_ads_2_img = data.horizontal_ads_2_img;
			this.horizontal_ads_2_url = data.horizontal_ads_2_url;
			this.horizontal_code_2 = data.horizontal_code_2;			
			this.titleService.setTitle( this.title + ' | Inter' );		
			this.videoCode = data.videoCode;
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoCode);
			this.metaService.updateTag({name: 'twitter:title', content: this.title});
			
		});		
		
	}

}
