<app-header></app-header>

<section>
	<div class="container py-5">

		<div class="d-flex align-items-center">
			<h1 class="article-title m-0" style="margin-right: 20px !important;" [innerHTML]="title"></h1>				
		</div>
		
		<div class="clear_20"></div>
		
		<div class="row">
		
			<div class="col-12 col-md-8">
				
				<ng-container *ngIf="categories;">
					<div class="videoteka-tags compact">
						<ng-container *ngFor="let categories_item of categories">
							<a *ngIf="categories_item.active; else elseBlock" class="tag-item active" href="{{categories_item.url}}" [innerHTML]="categories_item.title"></a>
							<ng-template #elseBlock><a class="tag-item" href="{{categories_item.url}}" [innerHTML]="categories_item.title"></a></ng-template>
						</ng-container>
						<div class="clear_30"></div>
					</div>
				</ng-container>					
			
				<div class="row news-append">
					<div *ngFor="let item of items" class="col-12 col-md-4">
						<div *ngIf="item.img">
							<a href="{{item.url}}" class="animated-image">
								<img src="{{item.img}}" class="w-100">
							</a>
						</div>
						<div class="clear_15"></div>
						<div class="article-item-meta-box">
							<a href="{{item.category_url}}" class="tag-item left active" [innerHTML]="item.category"></a>
							<p class="section-widget-meta">{{item.date}}</p>						
						</div>				
						<a href="{{item.url}}" class="section-widget-title" [innerHTML]="item.title"></a>
						<p [innerHTML]="item.announce"></p>
						<div class="clear_15"></div>
					</div>		
				</div>	
				<div class="text-center" *ngIf="is_last">
					<button class="section-widget-btn" (click)="load_more()" translate>More News</button>
				</div>				
			</div>
			
			<div class="col-12 col-md-4">		
				<app-sidebar></app-sidebar>			
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>