<app-header></app-header>

<section class="article-page">
	<div class="container py-5">
		
		<h2 class="article-title" translate>Rating</h2>
		
		<div class="videoteka-tags">
			<a class="tag-item" href="{{ urlLanguage }}/about" translate>About</a> 
			<a class="tag-item active" href="{{ urlLanguage }}/about/rating" translate>Rating</a> 
			<a class="tag-item" href="{{ urlLanguage }}/about/awards" translate>Awards</a> 
			<a class="tag-item" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a> 
			<a class="tag-item" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a> 
			<a class="tag-item" href="{{ urlLanguage }}/sputnik" translate>Satellite</a> 
			
			<a class="tag-item" href="http://інтер.укр" target="_blank" rel="nofollow" translate>Ownership structure</a>
			
		</div>
				
		<div class="clear_15"></div>		
		
		<div class="bg-white padding-36">
		
			<div class="row">
			
				<div class="col-12 col-md-8">
					
					<article>
					
						<h1 translate>Rating</h1>	
						<div class="clear_15"></div>
						<p><span translate>TOP-20 TV shows of the week with</span> 3 июня 2019 - 9 июня 2019</p>
						
						<div class="row align-items-center">
							<div class="col-12 col-sm-4">
								<a href="{{ urlLanguage }}/about/rating" class="rating-nav"><img src="assets/img/spec_arrow_right.svg" class="rating-nav-left m-0"> <span translate>previous week</span></a>
							</div>
							<div class="col-12 col-sm-4 text-left text-sm-center">
								<span class="rating-nav" translate>this week</span>
							</div>	
							<div class="col-12 col-sm-4 text-left text-sm-right">
								<a href="{{ urlLanguage }}/about/rating" class="rating-nav"><span translate>next week</span> <img src="assets/img/spec_arrow_right.svg" class="rating-nav-right m-0"></a>
							</div>								
						</div>
						
						<div class="clear_30"></div>
						
						<table class="table">
							<thead>
								<tr>
									<th translate>Program</th>
									<th translate>Date</th>
									<th translate>Time</th>
									<th><span translate>Rating</span> %</th>
									<th><span translate>Percentage</span> %</th>
								</tr>
							</thead>
							<tbody>
								
								<tr>
									<td><strong>Подробности</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 13%;" aria-valuenow="13" aria-valuemin="0" aria-valuemax="100">13.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 42.4%;" aria-valuenow="42.4" aria-valuemin="0" aria-valuemax="100">42.40%</div>
										</div>										
									</td>
								</tr>
								
								<tr>
									<td><strong>Касается каждого</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 7%;" aria-valuenow="7" aria-valuemin="0" aria-valuemax="100">7.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 22.4%;" aria-valuenow="22.4" aria-valuemin="0" aria-valuemax="100">22.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Я подарю тебе рассвет т/с</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Новости</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>		

								<tr>
									<td><strong>Подробности</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>
								
								<tr>
									<td><strong>Касается каждого</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Я подарю тебе рассвет т/с</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Новости</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>	

								<tr>
									<td><strong>Подробности</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>
								
								<tr>
									<td><strong>Касается каждого</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Я подарю тебе рассвет т/с</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Новости</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>	

								<tr>
									<td><strong>Подробности</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>
								
								<tr>
									<td><strong>Касается каждого</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Я подарю тебе рассвет т/с</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Новости</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>	

								<tr>
									<td><strong>Подробности</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>
								
								<tr>
									<td><strong>Касается каждого</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Я подарю тебе рассвет т/с</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>

								<tr>
									<td><strong>Новости</strong></td>
									<td>08.06</td>
									<td>20:00</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 3%;" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100">3.00%</div>
										</div>									
									</td>
									<td>
										<div class="progress">
											<div class="progress-bar" role="progressbar" style="width: 12.4%;" aria-valuenow="12.4" aria-valuemin="0" aria-valuemax="100">12.40%</div>
										</div>										
									</td>
								</tr>									
								
							</tbody>
						</table>
						
						<p>
							<span translate>The data from the study of the television audience belong to ITK, provided by the television</span> "<span translate>Inter</span>".<br>
							<span translate>Data calculated for audience 18+, region - all Ukraine</span>.<br> 
							<span translate>Data provided by rating and share indicators</span>.
						</p>
						
					</article>
					
				</div>
				
				<div class="col-12 col-md-4">				
					<app-sidebar></app-sidebar>					
				</div>			
			</div>
		
		</div>
		
	</div>
</section>

<app-footer></app-footer>