import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectComponent } from './project/project.component';
import { AllnewsComponent } from './allnews/allnews.component';
import { NewsComponent } from './news/news.component';
import { LiveComponent } from './live/live.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { CategoryComponent } from './category/category.component';
import { VideoComponent } from './video/video.component';
import { SpecprojectsComponent } from './specprojects/specprojects.component';
import { TvComponent } from './tv/tv.component';
import { RatingComponent } from './rating/rating.component';
import { CoordinatesComponent } from './coordinates/coordinates.component';
import { SputnikComponent } from './sputnik/sputnik.component';
import { LoginComponent } from './login/login.component';
import { CastingsComponent } from './castings/castings.component';
import { CastingInterscoolComponent } from './casting-interscool/casting-interscool.component';
import { NoComponent } from './no/no.component';
import { AllForYouCastingComponent } from './all-for-you-casting/all-for-you-casting.component';
const routes: Routes = [

	{ path: '', component: HomeComponent, data: { lang: 'ua' } },
	{ path: 'ua', component: HomeComponent, data: { lang: 'ua' } },
	{ path: 'ru', component: HomeComponent, data: { lang: 'ru' } },
	{ path: 'en', component: HomeComponent, data: { lang: 'en' } },
	
	{ path: 'programs', component: ProjectsComponent, data: { lang: 'ua' } },
	{ path: ':lang/programs', component: ProjectsComponent },
	
	{ path: 'login', component: LoginComponent, data: { lang: 'ua' } },
	{ path: ':lang/login', component: LoginComponent },	
	
	{ path: 'castings', component: CastingsComponent, data: { lang: 'ua' } },
	{ path: ':lang/castings', component: CastingsComponent },
	
	//{ path: 'all-for-you-casting', component: AllForYouCastingComponent, data: { lang: 'ua' } },
	//{ path: ':lang/all-for-you-casting', component: AllForYouCastingComponent },
	
	{ path: 'tv', component: TvComponent,  data: { lang: 'ua' } },
	{ path: ':lang/tv', component: TvComponent },	

	{ path: 'about/rating', component: RatingComponent,  data: { lang: 'ua' } },
	{ path: ':lang/about/rating', component: RatingComponent },		
	
	{ path: 'interschool-casting', component: CastingInterscoolComponent,  data: { lang: 'ua' } },
	{ path: ':lang/interschool-casting', component: CastingInterscoolComponent },		

	{ path: 'about/coordinates', component: CoordinatesComponent,  data: { lang: 'ua' } },
	{ path: ':lang/about/coordinates', component: CoordinatesComponent },

	{ path: 'sputnik', component: SputnikComponent,  data: { lang: 'ua' } },
	{ path: ':lang/sputnik', component: SputnikComponent },		
	
	{ path: 'live', component: LiveComponent,  data: { lang: 'ua' } },
	{ path: ':lang/live', component: LiveComponent },	
	
	{ path: ':lang/news', component: AllnewsComponent },
	{ path: 'news', component: AllnewsComponent,  data: { lang: 'ua' } },
	{ path: ':lang/:project/news', component: AllnewsComponent },
	{ path: ':lang/news/category/:slug', component: AllnewsComponent },
	{ path: ':lang/news/:slug', component: NewsComponent },
	{ path: ':lang/:project/news/:slug', component: NewsComponent },	
	{ path: 'news/:slug', component: NewsComponent,  data: { lang: 'ua' } },

	{ path: ':lang/vacancies/:slug', component: ProjectComponent },
	{ path: 'vacancies/:slug', component: ProjectComponent,  data: { lang: 'ua' } },

	{ path: ':lang/special-projects', component: SpecprojectsComponent },
	{ path: 'special-projects', component: SpecprojectsComponent,  data: { lang: 'ua' } },
	
	{ path: ':lang/playlist/:slug', component: PlaylistComponent },
	{ path: ':lang/:project/playlist/:slug', component: PlaylistComponent },	
	{ path: 'playlist/:slug', component: PlaylistComponent,  data: { lang: 'ua' } },
	
	{ path: ':lang/category/:slug', component: CategoryComponent },	
	{ path: 'category/:slug', component: CategoryComponent,  data: { lang: 'ua' } },	
	
	{ path: ':lang/video/:slug', component: VideoComponent,  data: { lang: 'ua' } },
	{ path: ':lang/:project/video/:slug', component: VideoComponent },	
	{ path: 'video/:slug', component: VideoComponent,  data: { lang: 'ua' } },
	
	/**
	* Projects and Page Logic
	*/

	{ path: ':lang/:slug', component: ProjectComponent },	
	{ path: ':slug', component: ProjectComponent, data: { lang: 'ua' } },

	{ path: 'error404', component: NoComponent },
	
	{ path: '**', redirectTo: 'ua' }
	
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
