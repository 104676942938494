import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

import { MatomoTracker } from 'ngx-matomo';

interface LiveInterface {
	title: string;
	content: string;
	playlists: any;
	video: any;
}

@Component({
	selector: 'app-live',
	templateUrl: './live.component.html',
	styleUrls: ['./live.component.css'],

})

export class LiveComponent implements OnInit {

	urlLanguage = '/ua';
	playlists = false;
	title = '';	
	content = '';	
    video: any;
	url: SafeResourceUrl;
	m3u_link = '';
	
	CarouselOptions2 = {
		responsiveClass: true,
		items: 3,
		nav: true,
		dots: false,
		loop: true,
		margin: 20,
		responsive: {
			'320':{
				items: 1
			},
			'560':{
				items: 2
			},			
			'960':{
				items: 3
			}
		}		
	};	
	
	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private http: HttpClient,
		public sanitizer:DomSanitizer,
		private matomoTracker: MatomoTracker
	) { }

	ngOnInit() {

		this.matomoTracker.setUserId('38');
    	this.matomoTracker.setDocumentTitle('Інтер онлайн | Inter');
		
		if (this.route.snapshot.params['slug']) {
			var slug = this.route.snapshot.params['slug'];
		}else{
			var slug = this.route.snapshot.data['slug'];
		}				
		if (this.route.snapshot.params['lang']) {
			var lang = this.route.snapshot.params['lang'];
		}else{
			var lang = this.route.snapshot.data['lang'];
		}					
		if(lang != undefined){
			this.urlLanguage = '/' + lang;		
		}else{
			this.urlLanguage = '/en';		
		}			
		if(lang == 'ua'){
			lang = 'uk';
		}
		if(lang == ''){
			lang = 'uk';
		}	
			
	

		this.http.get('https://lb1.macc.com.ua/inters_json/').subscribe(data => {
			this.m3u_link = data['redir'];

			var api_url = '//dashboard.inter.ua/api/tv/live/' + lang;	
			this.http.get<LiveInterface>(api_url).subscribe(data => {
				this.title = data.title;
				this.content = data.content;				
				this.playlists = data.playlists;
				this.titleService.setTitle( this.title + ' | Inter' );
				this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://dashboard.inter.ua/embed/?url='+this.m3u_link+'&poster=https://inter.ua/images/logo.png');	
			});	
			
		});

		
		
	}

}
