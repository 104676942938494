<app-header></app-header>

<section>
	<div class="container py-5">

		<h1 class="article-title" translate>All For You Casting</h1>

		<div class="clear_15"></div>
		<div class="row">
			<div class="col-12 col-md-8">

				<article>
					<p translate>"All for you" is a legendary project in which people do things for another person, make dreams come true and offer a hand and a heart</p>
          			<p translate>What are you capable of for love? Become a member of the program "Everything for you" - fill out the form below.</p>

					<p><span translate>Contact number</span> 067 376 5198</p>

					<div>
						<iframe width="100%" height="420" src="https://www.youtube.com/embed/mRh5gUJMdC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
					</div>

					<form class="form-anketa" method="POST" enctype="multipart/form-data" #castingISForm="ngForm" (ngSubmit)="onFormSubmit(castingISForm)">

						<div class="form-group">
							<label translate>Your name and surname</label>
							<input type="text" name="fio" class="form-control" ngModel required #profileFio="ngModel">
							<ng-container *ngIf="fio_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<label translate>Age</label>
							<input type="text" name="age" class="form-control" ngModel required #profileAge="ngModel">
							<ng-container *ngIf="age_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<label translate>Please provide a phone number where you can be reached</label>
							<input type="text" name="phone" class="form-control" ngModel required #profilePhone="ngModel">
							<ng-container *ngIf="phone_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>
						<div class="form-group">
							<label translate>Social Link</label>
							<input type="text" name="social_link" class="form-control" ngModel required #socialLink="ngModel">
							<ng-container *ngIf="social_link_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>

						<div class="form-group">
							<label translate>Name your partner</label>
							<input type="text" name="fio_partner" class="form-control" ngModel required #profileFio="ngModel">
							<ng-container *ngIf="fio_partner_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>

						<div class="form-group">
							<label translate>Social Link Partner</label>
							<input type="text" name="social_link_partner" class="form-control" ngModel required #socialLink="ngModel">
							<ng-container *ngIf="social_link_partner_error">
								<div class="clear_5"></div>
								<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
							</ng-container>
							<div class="clear_5"></div>
						</div>

						<div class="form-group">
							<div class="form-check">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" name="accept2" value="1" ngModel required #profileAccept2="ngModel"><span translate>I agree to the processing of my personal data and the publication of my data on the site</span>
								</label>
							</div>
						</div>
						<ng-container *ngIf="accept2_error">
							<div class="alert alert-danger px-2 py-0 small" role="alert" translate>Error! This field is required</div>
						</ng-container>
						
						<div class="clear_5"></div>
						<div *ngIf="profile_form_wait" class="alert alert-wait" role="alert" translate>Please wait while your application is sent to managers</div>
						<div *ngIf="profile_form_success" class="alert alert-success" role="alert" translate>We will call you soon!</div>
						<div *ngIf="profile_form_danger" class="alert alert-danger" role="alert" translate>Error sending questionnaire! Try later ...</div>
						<div class="text-right">
							<button *ngIf="profile_form_submit_btn" type="submit" class="tag-item big active" translate>Send Application</button>
						</div>
					</form>
				</article>

				<div class="clear_40"></div>

			</div>
			<div class="col-12 col-md-4">
				<app-sidebar></app-sidebar>
			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
