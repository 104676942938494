<section class="section-logos">
	<div class="container text-center">
		<h2 translate>Television for the whole family</h2>
		<div class="clear_20"></div>
		<div class="footer-logos">
			<a href="" class="footer-logo"><img src="assets/img/logos/efi.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/int.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/intpl.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/k1.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/k2.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/meg.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/ntn.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/pix.svg"></a>
			<a href="" class="footer-logo"><img src="assets/img/logos/zoom.svg"></a>
		</div>
	</div>
</section>
	
<footer>
	<div class="container">
		
		<div class="row">
			<div class="col-12 tex-left d-sm-none">
				<span translate>We are in social networks</span>: <a href="" class="footer-social"><img src="assets/img/facebook.svg"></a> <a href="" class="footer-social"><img src="assets/img/youtube.svg"></a> <a href="" class="footer-social"><img src="assets/img/twitter.svg"></a>
				<div class="clear_60"></div>
			</div>			
			<div class="col-4 col-md-3 col-lg-2">
				<ul class="nav">
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/about" translate>About</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/castings" translate>Castings</a></li>
					<!--
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/about/rating" translate>Rating</a></li>
					-->
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/awards" translate>Awards</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/vacancy" translate>Vacancies</a></li>
				</ul>
			</div>
			<div class="col-4 col-md-3 col-lg-2">
				<ul class="nav">
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/tv" translate>TV Program</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/video" translate>Video</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/programs" translate>TV Projects</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/news" translate>News</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/special-projects" translate>Special Projects</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/live" translate>Live</a></li>
				</ul>				
			</div>
			<div class="col-4 col-md-3 col-lg-2">
				<ul class="nav">
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/about/coordinates" translate>Contacts</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/sputnik" translate>Satellite</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/interschool" translate>Interschool</a></li>
					
					<li class="nav-item"><a class="nav-link" href="http://інтер.укр" target="_blank" rel="nofollow" translate>Ownership structure</a></li>
					<li class="nav-item"><a class="nav-link" href="{{ urlLanguage }}/news/category/informaciya-dlya-akcionerov-i-stejkholderov" translate>Information for shareholders and stakeholders</a></li>				
				</ul>				
			</div>
			<div class="col-6 col-md-3 col-lg-6 tex-left text-md-right d-none d-sm-flex justify-content-end">
				<span translate>We are in social networks</span>: 
				<a target="_blank" rel="nofollow" href="https://www.facebook.com/Inter.ua/" class="footer-social"><img src="assets/img/facebook.svg"></a> 
				<a target="_blank" rel="nofollow" href="https://www.youtube.com/user/InterTVua/" class="footer-social"><img src="assets/img/youtube.svg"></a> 
				<a target="_blank" rel="nofollow" href="https://www.instagram.com/inter_tv_channel/" class="footer-social"><img src="assets/img/instagram.svg"></a>
			</div>				
		</div>
			
		<div class="clear_40"></div>
			
		<div class="row">
			<div class="col-12 col-md-4 text-center text-md-left">
				<p><span translate>Inter is part of</span> Inter Media Group Limited</p>
			</div>
			<div class="col-12 col-md-4 text-center">
				<p><img src="assets/img/copyright.svg" style="margin-top: -3px;"> 2004 - {{ year }} Inter</p>
			</div>
			<div class="col-12 col-md-4 text-center text-md-right">
				<p translate>Website developed by Make Web</p>
			</div>				
		</div>

		<ng-container *ngIf="polit">
		<div class="warning">
			<p>Ми використовуємо cookies, щоб проаналізувати та покращити роботу нашого сайту, персоналізувати рекламу. Продовжуючи відвідування сайту, ви надаєте згоду на використання cookies та погоджуєтесь з <a href="/terms-of-use">Політикою конфіденційності.</a></p>
			<button class="accept_cookies" (click)="accept()">Погоджуюсь</button>
		  </div>
		</ng-container>

	</div>
</footer>