<app-header></app-header>

<section class="article-page">
	<div class="container white_container py-5">
		<h2 class="article-title" translate>News</h2>
		
		<div class="bg-white">
		
			<div class="row">
			
				<div class="col-12 col-md-8">
					
					<article class="pr-0">
						<div *ngIf="img">
							<img src="{{img}}" class="w-100">
							<div class="clear_20"></div>
						</div>
						<h1 [innerHTML]="title"></h1>
						<div class="article-meta">{{date}}</div>
						
						<div *ngIf="videoCode">
							<iframe *ngIf="url" width="100%" height="600" [src]="url" frameborder="0" scrolling="no" allowfullscreen></iframe>
							<div class="clear_15"></div>
						</div>						
						
						<div [innerHTML]="body"></div>		
						
						<div class="clear_15"></div>

						<div *ngIf="horizontal_ads_2_img; else adsHorizontal2Template">
							<a href="{{horizontal_ads_2_url}}" target="_blank" rel="nofollow">
								<img src="{{horizontal_ads_2_img}}" class="w-100">
							</a>		
							<div class="clear_60"></div>
						</div>
						<ng-template #adsHorizontal2Template>
							<div class="text-center">
								<div id="div-gpt-ad-1516984738506-1"></div>
							</div>
							<div class="clear_60"></div>
						</ng-template>	
						
					</article>
					
				</div>
				
				<div class="col-12 col-md-4">			
					<app-sidebar></app-sidebar>					
				</div>			
			</div>
		
		</div>
		
	</div>
</section>

<app-footer></app-footer>